import { Link } from '@mui/material';
import React from 'react';
import '../../styles/contact.scss';

const DevDocumentationDownloadLink = ({ label, document_title, url }) => {
    return (
        <Link
            download={document_title}
            underline='always'
            target='_blank'
            href={url}
            sx={{ color: '#02555b !important', fontWeight: '700 !important', textDecoration: 'underline !important' }}
        >
            {label}
        </Link>
    )
}

export default DevDocumentationDownloadLink;