import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationTokenLink from '../../../containers/staticsite/dev-documentation-token-link';

const TransactionApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Transaction Query API</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Transaction API</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            The Pesaswap Transaction API provides the users what they need to know about their transactions especially the current status of their payment. Would it be Completed, Pending or Failed and other important details.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            Completed status means the payment has been collected successfully from the customer. If it's still Pending status, the payment has been successfully processed on MPesa end but still waiting for the customer to execute the actual payment on their phone. Once it is Failed, means customer reject the payment call. For this case, you can send another api payment call to the customer.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            The Pesaswap Transaction API has sandbox environment which you can request from Pesaswap Administrator for testing purposes. sandbox environment serves as a simulation of how API's work in production. Sandbox and Production keys are two different keys
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect into Pesaswap Transaction Query API?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1' component={'span'}>
                            Note: You needed header token. Please refer to <DevDocumentationTokenLink />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="GET" color='info' sx={{ marginRight: 1 }} /> Get Tranasction List
                                </Typography>
                                <Typography variant='body1' pt={1}>To fetch transaction list you need to make a GET call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap-csharp.azurewebsites.net/api/transactions/list'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://api.pesaswap.com/api/transactions/list'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationHeaders
                                    title="Headers"
                                    headers={['Field', 'Type', 'Value', 'Additional']}
                                    values={[
                                        ['Content-Type', 'string', 'application/json', 'Required'],
                                        ['Authorization', 'string', 'Value from Tokenization API.', 'Required']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Body Request Parameters"
                                    headers={['Field', 'Type', 'Value', 'Additional']}
                                    values={[
                                        ['MerchantIdentifier', 'string', '<your merchant identifier>', 'Required'],
                                        ['TransactionExternalId', 'string', '<your unique key identifier>', ''],
                                        ['FromDate', 'date(YYYY-MM-DD)', '<starting date>', 'Required'],
                                        ['ToDate', 'date(YYYY-MM-DD)', '<end date>', 'Required'],
                                        ['Status', 'number', '<1-Completed, 2-Failed, 3-Pending>', ''],
                                        ['Skip', 'number', '<page number>', ''],
                                        ['Take', 'number', '<page size>', ''],
                                        ['SearchParam', 'string', '<value will be search in these columns: ExternalId, PhoneNumber, MpesaTransactionId, Method, Amount, MpesaCustomer,Remarks>', '']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request'}
                                    content={{
                                        "MerchantIdentifier": "MerchantIdentifier",
                                        "TransactionExternalId": "TransactionExternalId",
                                        "FromDate": "2022-04-01",
                                        "ToDate": "2022-04-30",
                                        "Status": 1,
                                        "Skip": 0,
                                        "Take": 10,
                                        "SearchParam": "254705281234"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response'}
                                    content={{
                                        "count": 10,
                                        "result": [
                                            {
                                                "transactionExternalId": "12345674",
                                                "transactionId": null,
                                                "businessName": "Pesaswap",
                                                "utilityFunds": null,
                                                "workingFunds": null,
                                                "type": "sales",
                                                "status": "Failed",
                                                "description": "DS timeout user cannot be reached",
                                                "amount": 14060,
                                                "currency": "KES",
                                                "gatewayFee": 0,
                                                "processorFee": 0,
                                                "totalFee": 0,
                                                "customer": "",
                                                "mpesaCustomer": null,
                                                "method": "OP",
                                                "phone": "254705281234",
                                                "dateCreated": "2023-07-01T22:49:13",
                                                "eaDate": "2023-07-02T01:49:13"
                                            }
                                        ]
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Transaction API</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect into Pesaswap Transaction Query API?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default TransactionApiDocumentation;
