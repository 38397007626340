import { Box, Button, Modal, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};

const DevDocumentationImage = ({ src }) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <img
                onClick={handleOpen}
                src={src}
                loading="lazy"
                alt="true"
                width={'100%'}
                style={{
                    borderRadius: '10px',
                    marginBottom: '1rem',
                    border: '1px solid #e3e3e3',
                    cursor: 'pointer'
                }}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img
                        src={src}
                        loading="lazy"
                        alt="true"
                        width={'100%'}
                    />
                    <Button variant='contained' color='info' onClick={handleClose} sx={{ mt: 1 }}>Close</Button>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default DevDocumentationImage;