import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../styles/contact.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DevDocumentationNote = ({ title, children }) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Box sx={{ borderRadius: 2, border: '1px solid #04b4c1' }}>
            <Box sx={{ backgroundColor: '#04b4c1', borderTopLeftRadius: 2, borderTopRightRadius: 2 }}>
                <Typography variant='body2' sx={{ fontWeight: '400', textTransform: 'uppercase' }} py={0.5} px={2}>{title}</Typography>
            </Box>
            <Box px={2} py={1}>
                {children}
            </Box>
        </Box>
    )
}

export default DevDocumentationNote;