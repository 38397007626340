import { Avatar, Box, Button, Container, Grid, Link, Paper, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { pesaswapLogoOnly } from '../../assets/images';
import { convenientImage, costeffectiveImage, stockImage1, timelyImage } from '../../assets/static/images';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/contact.scss';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

const DevOverviewStaticPage = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    React.useEffect(() => {
        setTimeout(() => {
            document.querySelector('main').style.opacity = 1;
            document.querySelector('footer').style.opacity = 1;
            document.querySelector('main').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            document.querySelector('footer').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            var sectionElements = document.querySelectorAll("section");
            for (var index = 0; index < sectionElements.length; index++) {
                sectionElements[index].style.opacity = 1;
            }
            window.scrollTo(0, 0);
        }, 1000);
    }, []);

    return (
        <React.Fragment>
            <section id="paymentsx" data-w-id="2574d8fb-155d-fd5d-b76a-6d007ccf5ded" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_layout">
                <div className="af-class-padding-global">
                    <div className="af-class-container-large">
                        <div className="af-class-padding-section-head">
                            <div className="w-layout-grid af-class-layout_component">
                                <div className="af-class-layout_content">
                                    <div className="af-class-margin-bottom af-class-margin-medium">
                                        <h3 className="af-class-title-head-section">Build with Pesaswap APIs</h3>
                                    </div>
                                    <div className="af-class-margin-bottom af-class-margin-medium">
                                        <p className="af-class-text-size-medium af-class-subtitle-head-section">Great news for the developer community as Pesaswap has opened its APIs and Sandbox platform to allow fast and easy access to our APIs!</p>
                                    </div>
                                    <div className="af-class-margin-20-0">
                                        <div className="af-class-button-group">
                                            <Link to="/dev-documentation/" component={RouterLink} className="af-class-button w-button">Read API Documents</Link>
                                        </div>
                                    </div>
                                </div>
                                <div id="w-node-_2574d8fb-155d-fd5d-b76a-6d007ccf5dfe-b5133c56" className="af-class-layout_image-wrapper" style={{ display: 'flex' }}>
                                    <img src="/images/Pesaswap-Logo.svg" loading="lazy" sizes="(max-width: 991px) 90vw, 41vw" srcSet="/images/Pesaswap-Logo.svg 500w, /images/Pesaswap-Logo.svg 800w, /images/Pesaswap-Logo.svg 1080w, /images/Pesaswap-Logo.svg 1600w" alt="true" className="af-class-layout_imagex" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Box component="main" sx={{ pt: 3, pb: 2.25, width: '100%' }} >
                <Container disableGutters maxWidth sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(225, 237, 238, .3)', paddingBottom: '122px' }}>
                    <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingLeft: matches767 ? 0 : '119px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                        <Box display={'flex'} sx={{ flexDirection: 'column', textAlign: 'center' }}>
                            <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Get Started with Guides</Typography>
                        </Box>
                    </Box>
                    <Box display={'flex'} sx={{ marginTop: '104px', marginLeft: 'auto', marginRight: 'auto', gap: '34px', flexDirection: matches767 ? 'column' : 'row' }}>
                        <Box sx={{ maxWidth: '403px', backgroundColor: '#fff', borderRadius: '10px', display: 'flex', boxSizing: 'border-box' }}>
                            <Box sx={{ padding: '25px 37px' }}>
                                <Typography sx={{ fontSize: '23px', fontWeight: 700, letterSpacing: '0.1px' }}>Account Balance</Typography>
                                <Typography sx={{ fontSize: '17px', letterSpacing: '.2px', lineHeight: 1.4, paddingTop: '15.5px' }}>
                                    The Pesaswap Balance API provides the users what they need to know about their transactions balance on Mpesa mainly. Other processor like Airtel Money to be followed since partner failed to provide the said query still.
                                </Typography>
                                <Box sx={{ paddingTop: '31px' }}>
                                    <Link component={RouterLink} to="/dev-documentation/mpesa-balance" sx={{ fontWeight: 600, fontSize: '20px' }}>Go to Guide</Link>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ maxWidth: '403px', backgroundColor: '#fff', borderRadius: '10px', display: 'flex', boxSizing: 'border-box' }}>
                            <Box sx={{ padding: '25px 37px' }}>
                                <Typography sx={{ fontSize: '23px', fontWeight: 700, letterSpacing: '0.1px' }}>Transaction</Typography>
                                <Typography sx={{ fontSize: '17px', letterSpacing: '.2px', lineHeight: 1.4, paddingTop: '15.5px' }}>
                                    The Pesaswap Transaction API provides the users what they need to know about their transactions especially the current status of their payment. Would it be Completed, Pending or Failed and other important details.
                                </Typography>
                                <Box sx={{ paddingTop: '31px' }}>
                                    <Link component={RouterLink} to="/dev-documentation/transaction" sx={{ fontWeight: 600, fontSize: '20px' }}>Go to Guide</Link>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ maxWidth: '403px', backgroundColor: '#fff', borderRadius: '10px', display: 'flex', boxSizing: 'border-box' }}>
                            <Box sx={{ padding: '25px 37px' }}>
                                <Typography sx={{ fontSize: '23px', fontWeight: 700, letterSpacing: '0.1px' }}>Reconciliation</Typography>
                                <Typography sx={{ fontSize: '17px', letterSpacing: '.2px', lineHeight: 1.4, paddingTop: '15.5px' }}>
                                    Other than automated throwing of callback response, we also have a manual trigger via API call called Reconciliation API. It automatically matches transactions, fees, and settlement records across all your payment gateways and services.
                                </Typography>
                                <Box sx={{ paddingTop: '31px' }}>
                                    <Link component={RouterLink} to="/dev-documentation/reconciliation-api" sx={{ fontWeight: 600, fontSize: '20px' }}>Go to Guide</Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display={'flex'} sx={{ marginTop: '30px', marginLeft: 'auto', marginRight: 'auto', gap: '34px', flexDirection: matches767 ? 'column' : 'row' }}>
                        <Box sx={{ maxWidth: '403px', backgroundColor: '#fff', borderRadius: '10px', display: 'flex', boxSizing: 'border-box' }}>
                            <Box sx={{ padding: '25px 37px' }}>
                                <Typography sx={{ fontSize: '23px', fontWeight: 700, letterSpacing: '0.1px' }}>Wallet</Typography>
                                <Typography sx={{ fontSize: '17px', letterSpacing: '.2px', lineHeight: 1.4, paddingTop: '15.5px' }}>
                                    Wallet API is your shortcut to an optimized checkout process. Our intelligent platform makes accepting payments easier while maximizing sales. And our fraud tools mitigate risk to keep your business safe.
                                </Typography>
                                <Box sx={{ paddingTop: '31px' }}>
                                    <Link component={RouterLink} to="/dev-documentation/wallet-airtel" sx={{ fontWeight: 600, fontSize: '20px' }}>Go to Guide</Link>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ maxWidth: '403px', backgroundColor: '#fff', borderRadius: '10px', display: 'flex', boxSizing: 'border-box' }}>
                            <Box sx={{ padding: '25px 37px' }}>
                                <Typography sx={{ fontSize: '23px', fontWeight: 700, letterSpacing: '0.1px' }}>Card Payment</Typography>
                                <Typography sx={{ fontSize: '17px', letterSpacing: '.2px', lineHeight: 1.4, paddingTop: '15.5px' }}>
                                    Pesaswap offered a REST API for regular card payment(unenrolled 3d-secure card) with Fraud.Net banking API in the middle as a fraud manager.
                                </Typography>
                                <Box sx={{ paddingTop: '31px' }}>
                                    <Link component={RouterLink} to="/dev-documentation/card-payment" sx={{ fontWeight: 600, fontSize: '20px' }}>Go to Guide</Link>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ maxWidth: '403px', backgroundColor: '#fff', borderRadius: '10px', display: 'flex', boxSizing: 'border-box', opacity: 0, userSelect: 'none' }}>
                            <Box sx={{ padding: '25px 37px' }}>
                                <Typography sx={{ fontSize: '23px', fontWeight: 700, letterSpacing: '0.1px' }}>Interswitch</Typography>
                                <Typography sx={{ fontSize: '17px', letterSpacing: '.2px', lineHeight: 1.4, paddingTop: '15.5px' }}>
                                    Lorem ipsum dolor sit amet, consectetuer
                                    adipiscing elit, sed diam nonummy nibh
                                    euismod tincidunt ut
                                </Typography>
                                <Box sx={{ paddingTop: '31px' }}>
                                    <Link sx={{ fontWeight: 600, fontSize: '20px' }}>Go to Guide</Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ paddingTop: '55px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <Button color="primary" variant='contained' size='large' sx={{ textTransform: 'none', borderRadius: '12px', height: '47px', width: '318px', fontWeight: 700, fontSize: '14px', paddingBottom: '5px', letterSpacing: '1px' }}>
                            View All API Documents
                        </Button> */}
                        <div className="af-class-button-group">
                            <Link to="/dev-documentation/" component={RouterLink} className="af-class-button w-button">View All API Documents</Link>
                        </div>
                    </Box>
                </Container>
            </Box>
            <section data-w-id="ae67c618-b9b8-b229-9df2-822271018b37" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_cta">
                <div className="af-class-padding-global">
                    <div className="af-class-container-large">
                        <div className="af-class-padding-section-large">
                            <div className="w-layout-grid af-class-cta51_component">
                                <div className="af-class-cta51_card">
                                    <div className="af-class-text-align-center">
                                        <div className="af-class-max-width-large">
                                            <div className="af-class-margin-bottom af-class-margin-small">
                                                <h2 className="af-class-cta_title"><strong>Start receiving and making digital payments with Pesaswap.</strong></h2>
                                            </div>
                                            <p className="af-class-text-size-medium">Create your free account in minutes and join the many businesses using Pesaswap.</p>
                                        </div>
                                    </div>
                                    <div className="af-class-margin-top af-class-margin-medium">
                                        <div className="af-class-button-group af-class-is-center">
                                            <a href={`${window.location.origin}/v2/merchant-registration`} className="af-class-button af-class-is-alternate w-button">Get Started</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    );
};

export default DevOverviewStaticPage;