import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../styles/contact.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DevDocumentationTableOfContents = (props) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);
    
    return (
        <Box display={'flex'} sx={{ position: 'sticky', top: 0, width: '100%', paddingTop: '256px', paddingBottom: '64px', paddingLeft: matches767 ? 0 : '5%', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
            <Box display={'flex'} sx={{ flexDirection: 'column', borderLeft: '3px solid #f3f3f3' }}>
                <Box p={2}>
                    <Typography component={'span'} fontWeight={'bolder'} sx={{ color: "#484848" }}>
                        <InsertDriveFileIcon fontSize='small' sx={{ mr: 1 }} />
                        Table of Contents
                    </Typography>
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
}

export default DevDocumentationTableOfContents;