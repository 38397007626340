import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationNote from '../../../containers/staticsite/dev-documentation-note';
import DevDocumentationContactUsLink from '../../../containers/staticsite/dev-documentation-contact-link';

const SemiAnnuallyRecurringBillingApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Recurring Billing</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            An advance feature of payement request. Also known as automated or scheduled payment request. Instead of performing one time fee or one time payment for a subscription type business(IE. Daily, weekly, Monthly, Quarterly, Semi-Annually, Annually, Terms), We strongly advise the API users to use Pesaswap Recurring Billing API to avoid the hassle of creating a payment request over and over again with similar details. The Pesaswap Recurring Billing API will be responsible of sending a payment request to the customer's email or phone base on the range set by the api users.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            The Pesaswap Recurring Billing API has sandbox environment which you can request from Pesaswap Administrator for testing purposes. sandbox environment serves as a simulation of how API's work in production. Sandbox and Production keys are two different keys
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect into Pesaswap Recurring Billing API?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: You needed the API and Consumer Key.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DevDocumentationNote title="Keys">
                            <Typography variant='body2'>
                                Consumer and API Key, These are the keys to be used by merchants to connect into Pesaswap Recurring Billing API. It should be included in the request body to determine if merchant has valid access to our API.
                            </Typography>
                        </DevDocumentationNote>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Semi-Annually Fee Recurring</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Request
                                </Typography>
                                <Typography variant='body1' pt={1}>To submit billing you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap.azurewebsites.net/api/payment/request/recurring/billing'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://www.pesaswap.com/api/payment/request/recurring/billing'} />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Body Request Parameters"
                                    headers={['Field', 'Description', 'Additional']}
                                    values={[
                                        ['consumer_key', <DevDocumentationContactUsLink statement='This serves as an api username. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['api_key', <DevDocumentationContactUsLink statement='This serves as an api user password. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['paybill_description', <DevDocumentationContactUsLink statement='Every api must have an admin settings. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['description', 'Transaction description. IE. Payment for goods.', 'Required'],
                                        ['range', '6 - Semi-Annually Fee (Every 6 months).', 'Required'],
                                        ['billing_date', 'The first billing date. Scheduler started sending automated payment request on this date on a quarterly basis. NOTE: This must be beyond the current date. format: mm/dd/yyyy. IE. 10/24/2019', 'Required'],
                                        ['last_billing_date', 'The last billing date. The sending of automated payment request will stop until this date base on range format: mm/dd/yyyy. IE. 01/24/2020', 'Required'],
                                        ['external_id', `Customer external_id. This must be generated on api user's end and must be included on Pesaswap Create Customer API Call. Note: If this is provided, phone is no longer needed(exclude in the parameter). You can send request to multiple customers by using comma(,) as delimiter. IE. customer_external_id#1, customer_external_id#2. It means you are sending the payment request to two(2) different customer.`, ''],
                                        ['phone_number', `Assuming you don't have customer's yet on Pesaswap End.You can still send Payment Request through phone numbers(Maximum of 9). Note: You must exclude the external_id to let the pesaswap api know that you will send a request to their phones. You can also send request to multiple phones by using comma(,) as delimiter. IE. 254708374148, 254708374149. It means you are sending the payment request to two(2) different phones.`, ''],
                                        ['total_amount', 'In recurring billing, The total amount will be devided base on the number of scheduled payment or range. IE. If Semi-Annually Fee set on 10/24/2019 with last billing date of 04/24/2020(2 Semis) with the total amount of 60, therefore the Semi-Annually billing amount is equal to 30.', 'Required'],
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request - Multiple Customer'}
                                    content={`
$url = BASE URL;
$curl_init();
curl_setopt($curl, CURLOPT_URL, $url); curl_setopt($curl, CURLOPT_HTTPHEADER, array('Content-Type:application/json'));

$curl_post_data = array(
'consumer_key' => '',
'api_key' => '',
'paybill_description' => '',
'description' => '',
'range' => '6',
'external_id' => 'external_id#1,external_id#2',
'billing_date' => '10/24/2019',
'last_billing_date' => '04/24/2020',
'total_amount' => '' );

$data_string = json_encode($curl_post_data);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_POST, true);
curl_setopt($curl, CURLOPT_POSTFIELDS, $data_string);
$curl_response = curl_exec($curl);
$response = json_decode($curl_response,true);
                                    `}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response - Multiple Customer'}
                                    content={"Payment Request has been created successfully to customer."}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request - Multiple Phone Number'}
                                    content={`
$url = BASE URL;
$curl_init();
curl_setopt($curl, CURLOPT_URL, $url); curl_setopt($curl, CURLOPT_HTTPHEADER, array('Content-Type:application/json'));

$curl_post_data = array(
'consumer_key' => '',
'api_key' => '',
'paybill_description' => '',
'description' => '',
'range' => '6',
'phone_number' => '254708374148,254708374149',
'billing_date' => '10/24/2019',
'last_billing_date' => '04/24/2020',
'total_amount' => '' );

$data_string = json_encode($curl_post_data);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_POST, true);
curl_setopt($curl, CURLOPT_POSTFIELDS, $data_string);
$curl_response = curl_exec($curl);
$response = json_decode($curl_response,true);
                                    `}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response - Multiple Phone Number'}
                                    content={"Payment Request has been successfully sent to phone number."}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Error Response'}
                                    content={`
- An error will be shown if parameter phone_number is empty for multiple phone API call. => Phone number is required.

- An error will be shown if parameter external_id is not exist for multiple customer API call. => external_id does not exist:750719F0-9364-11E9-B2DD-5399F21EC503s

- An error will be shown if parameter total_amount is not a whole number. => Invalid total_amount. Please enter whole number.

- An error will be shown if the billing_date is not beyond current date. => First billing date must beyond the current date.

- An error will be shown if the last_billing_date is not beyond billing_date. => Last billing date must beyond the first billing date.
                                    `}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Recurring Billing</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect into Pesaswap Recurring Billing API?</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>Semi-Annually Fee Recurring</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default SemiAnnuallyRecurringBillingApiDocumentation;
