import { AppBar, Avatar, Box, Button, Collapse, Container, Dialog, Divider, Drawer, Fab, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Slide, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { pesaswapLogoOnly } from '../../assets/images';
import { convenientImage, costeffectiveImage, stockImage1, timelyImage } from '../../assets/static/images';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/contact.scss';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const ParentMenuItem = ({ title, children }) => {
    const [menuItemOpen, setMenuItemOpen] = useState(false)

    return (
        <React.Fragment>
            <ListItem disablePadding style={{ paddingLeft: 0 }}>
                <ListItemButton sx={{ py: 1.5 }} onClick={() => setMenuItemOpen(!menuItemOpen)}>
                    <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={title} />
                    {menuItemOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={menuItemOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </React.Fragment>
    )
}

const DocumentationPopupMenu = () => {
    const history = useHistory()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigateDocumentation = (url) => {
        setOpen(false);
        history.push(`/dev-documentation/${url}`)
    }

    return (
        <React.Fragment>
            {/* FAB Menu */}
            <Fab onClick={handleClickOpen} variant="extended" size="small" color="primary" sx={{ color: '#fff !important', alignItems: 'center', position: 'fixed', top: '87px', left: '20px', padding: '0 15px', display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
                <MenuOpenOutlinedIcon sx={{ mr: 1 }} />
                Menu
            </Fab>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            API Documentation Menu
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List sx={{ pl: "0!important" }}>
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton sx={{ py: 1.5 }} onClick={() => navigateDocumentation("api-keys")}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"API Keys"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ParentMenuItem title="Account Balance">
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("mpesa-balance")}>
                                <ListItemText primary="Mpesa Balance" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title={"Collection (new)"}>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("collection-api")}>
                                <ListItemText primary="Collection API" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("c2b-collection-api")}>
                                <ListItemText primary="C2B API" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton sx={{ py: 1.5 }} onClick={() => navigateDocumentation("disbursement-api")}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"Disbursement API"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ParentMenuItem title="Tokenizations">
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("header-tokenization")}>
                                <ListItemText primary="Header Tokenization" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("iveri-tokenization")} disabled>
                                <ListItemText primary="Iveri Tokenization" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("checkout-tokenization")}>
                                <ListItemText primary="Checkout Tokenization" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title="Tranasction Query API">
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("transaction")}>
                                <ListItemText primary="Tranasction API" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton sx={{ py: 1.5 }} onClick={() => navigateDocumentation("reconciliation-api")}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"Reconciliation API"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton sx={{ py: 1.5 }} onClick={() => navigateDocumentation("refund-api")}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"Refund API"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ParentMenuItem title="Payment Request">
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("send-payment")}>
                                <ListItemText primary="Send Payment" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title="Recurring Billing">
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("recurring-billing")}>
                                <ListItemText primary="Daily Fee" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("weekly-recurring-billing")}>
                                <ListItemText primary="Weekly Fee" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("monthly-recurring-billing")}>
                                <ListItemText primary="Monthly Fee" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("quarterly-recurring-billing")}>
                                <ListItemText primary="Quarterly Fee" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("semi-annually-recurring-billing")}>
                                <ListItemText primary="Semi-Anually Fee" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("annually-recurring-billing")}>
                                <ListItemText primary="Anually Fee" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("terms-recurring-billing")}>
                                <ListItemText primary="Terms" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    {/* ================================================== */}
                    <ParentMenuItem title="Wallet (new)">
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("wallet-airtel")}>
                                <ListItemText primary="Airtel" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("wallet-balance")}>
                                <ListItemText primary="Balance Summary" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("wallet-checkout")}>
                                <ListItemText primary="3d Secure Checkout Page" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("wallet-mpesa")}>
                                <ListItemText primary="Mpesa" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("wallet-refund")}>
                                <ListItemText primary="Refund" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4, py: 0 }} onClick={() => navigateDocumentation("wallet-transaction")}>
                                <ListItemText primary="Transactions" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }} onClick={() => navigateDocumentation("woocommerce")}>
                        <ListItemButton sx={{ py: 1.5 }}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"Woocommerce"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }} onClick={() => navigateDocumentation("checkout")}>
                        <ListItemButton sx={{ py: 1.5 }}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"Checkout Pages"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }} onClick={() => navigateDocumentation("card-payment")}>
                        <ListItemButton sx={{ py: 1.5 }}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"Card Payment API"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }} onClick={() => navigateDocumentation("callback")}>
                        <ListItemButton sx={{ py: 1.5 }}>
                            <ListItemText primaryTypographyProps={{ fontWeight: '900' }} primary={"Callback"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Dialog>
        </React.Fragment>

    );
}

export default DocumentationPopupMenu;