import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationImage from '../../../containers/staticsite/dev-documentation-image';
import DevDocumentationDownloadLink from '../../../containers/staticsite/dev-documentation-download-link';
import headerTokenizationDownload from '../../../downloadables/collection/header-tokenization.zip'
import DevDocumentationContactUsLink from '../../../containers/staticsite/dev-documentation-contact-link';

const HeaderTokenizationApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Tokenization</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Header Tokenization</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1' component={'span'}>
                            Download the <DevDocumentationDownloadLink label={'header tokenization Postman collection API here.'} document_title={'Header Tokenization API Postman Collection'} url={headerTokenizationDownload} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to generate header token?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: You must be logged in as merchant to the portal. Merchant needs to submit all the required documents first for KYC purposes before it can access the API and Consumer keys.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={1} />
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box>
                                    <DevDocumentationImage src="/images/api-documentation/api-key-ss-1.jpg" />
                                    <Typography variant='body1'>
                                        1. Go to Profile and Click "API Keys and Credentials"
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box>
                                    <DevDocumentationImage src="/images/api-documentation/api-key-ss-2.jpg" />
                                    <Typography variant='body1'>
                                        2. You can now see your API keys and other credentials and can be used to perform the request to generate the header token
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Request
                                </Typography>
                                <Typography variant='body1' pt={1}>To get access token you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap-csharp.azurewebsites.net/api/tokenization'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://api.pesaswap.com/api/tokenization'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Body Request Parameters"
                                    headers={['Field', 'Description', 'Additional']}
                                    values={[
                                        ['ConsumerKey', <DevDocumentationContactUsLink statement='This serves as an api username. You can request this key from administrator or please contact us for more details.' />, 'Required for Mpesa only'],
                                        ['ApiKey', <DevDocumentationContactUsLink statement='This serves as an api user password. You can request this key from administrator or please contact us for more details.' />, 'Required for others, except for Mpesa']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request'}
                                    content={{
                                        "ConsumerKey": "your-ConsumerKey",
                                        "ApiKey": "your-ApiKey"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response'}
                                    content={{
                                        "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiUG93ZXJlZCBCeSBKV1QgVGVjaG5vbG9neSIsImV4cCI6MTY0NzUyOTUxMSwiaXNzIjoiaHR0cHM6Ly9kZXZwZXNhc3dhcC1jc2hhcnAuYXp1cmV3ZWJzaXRlcy5uZXQvIiwiYXVkIjoiaHR0cHM6Ly9kZXZwZXNhc3dhcC1jc2hhcnAuYXp1cmV3ZWJzaXRlcy5uZXQvIn0.aDQLlboozcqWFiUsMF25HdWua3QDITDqS3VmZsyVQQA",
                                        "expiry": "Thu, 01 Dec 2022 15:05:11 UTC"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Error Response'}
                                    content={"Unauthorized - HTTP Error 401"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Header Tokenization</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to generate header token?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default HeaderTokenizationApiDocumentation;
