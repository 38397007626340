import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';


const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
    const userDetails = true;
    return (
        <Route path={path} render={(props) => isPrivate && !Boolean(userDetails.token) ? (
            <Redirect to={{ pathname: '/signin' }} />) : (
            <Component {...props} />
        )}
            {...rest}
        />
    );
};

export default AppRoutes;