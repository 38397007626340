import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';

const CheckoutTokenizationApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Tokenization</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Checkout Tokenization</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Tokenization is part of the checkout process. It is also serve as an identity of a merchant for a secured payment process.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to generate checkout token?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: You must be logged in as merchant to the portal. Merchant needs to submit all the required documents first for KYC purposes before it can access the API and Consumer keys.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Request
                                </Typography>
                                <Typography variant='body1' pt={1}>To generate checkout token you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap.azurewebsites.net/api/generate-checkout-token'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://www.pesaswap.com/api/generate-checkout-token'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Body Request Parameters"
                                    headers={['Field', 'Value', 'Additional']}
                                    values={[
                                        ['ConsumerKey', 'Merchant Consumer Key', 'Required for Mpesa only'],
                                        ['ApiKey', 'Merchant API Key', 'Required for others, except for Mpesa']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request'}
                                    content={`
$url = BASE URL;
$curl_init();
curl_setopt($curl, CURLOPT_URL, $url); curl_setopt($curl, CURLOPT_HTTPHEADER, array('Content-Type:application/json'));

$curl_post_data = array(
'consumer_key' => '',
'api_key' => ''
);

$data_string = json_encode($curl_post_data);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_POST, true);
curl_setopt($curl, CURLOPT_POSTFIELDS, $data_string);
$curl_response = curl_exec($curl);
$response = json_decode($curl_response,true);
                                    `}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Example Success Response"
                                    headers={['Description', 'Value']}
                                    values={[
                                        ['The ‘token’ will be generated which will be used in one of the checkout page parameters. It will expire in 1 hour prior to the time of creation.', '{ "token": "0f3b9af0-219a-11ea-992e-ff8147e8420a", "expiration": "yyyy-mm-dd hh:mm:ss" }'],
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Example Error Response"
                                    headers={['Description', 'Value']}
                                    values={[
                                        ['An error will be raised if tokenization failes.', '{ "error": "Invalid Credentials. Please Check your consumer_key and api_key." }'],
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
           <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Checkout Tokenization</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to generate checkout token?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default CheckoutTokenizationApiDocumentation;
