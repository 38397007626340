import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Fab, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState, Suspense } from 'react';
import { pesaswapLogoOnly } from '../../assets/images';
import { convenientImage, costeffectiveImage, stockImage1, timelyImage } from '../../assets/static/images';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/contact.scss';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DocumentationSideMenu from '../../containers/staticsite/dev-documentation.sidemenu';
import ApiKeyDocumentation from './api_documentation/api-key.documentation';
import { Switch, Route } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DocumentationPopupMenu from '../../containers/staticsite/dev-documentation.popupmenu';

const DevDocumentationStaticPage = (props) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);
    const location = useLocation();
    const history = useHistory()

    React.useEffect(() => {
        setTimeout(() => {
            document.querySelector('main').style.opacity = 1;
            document.querySelector('footer').style.opacity = 1;
            document.querySelector('main').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            document.querySelector('footer').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            var sectionElements = document.querySelectorAll("section");
            for (var index = 0; index < sectionElements.length; index++) {
                sectionElements[index].style.opacity = 1;
            }
            window.scrollTo(0, 0);
        }, 1000);

        let tmp_location = location;
        tmp_location = (tmp_location?.pathname || "").split("/")
        tmp_location = tmp_location.filter(x => x.length != 0).length
        if (tmp_location === 1) {
            history.push('/dev-documentation/api-keys')
        }
    }, []);

    return (
        <React.Fragment>
            {/* <Toolbar className='toolbar-placeholder'/> */}
            <Box component="main" display={'flex!important'} sx={{ pb: 2.25, width: '100%' }}>
                {/* Sidemenu */}
                <DocumentationSideMenu />
                {/* Popup Menu */}
                <DocumentationPopupMenu />


                <Container disableGutters maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(255, 255, 255, 1)', px: 3 }}>
                    {/* Content */}
                    {props.children}

                    {/* <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', paddingLeft: matches767 ? 0 : '5%', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                        <Box display={'flex'} sx={{ flexDirection: 'column', textAlign: 'center' }}>
                            <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Account Balance</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Account Balance</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>
                                API keys and other credentials will be used for the merchant to connect Pesaswap services like REST API, card payment checkout pages and Woocommerce plugins from several payment processors.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Mpesa Account Balance Request Parameters</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>
                                An API intended to show your actual payment processor balance. Note: typically the query might take up to 30 seconds before it can fully process the request and give back the response from mpesa. It is due to mpesa asynchronous approach. we never know exactly when they send us back the response. So the query will ping the backend and will check if the callback has been received, and then show it to the merchant.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to view API Keys and Credentials?</Typography>
                        </Grid>
                        <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                            <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                                Description
                            </div>
                            <Typography variant='body1'>
                                Note: Merchant can only view the credentials after required docs submission.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <Box>
                                        <img src="https://via.placeholder.com/600x400" width={'100%'} style={{ borderRadius: '10px', marginBottom: '1rem' }} />
                                        <Typography variant='body1'>
                                            1. Go to Profile and Click "API Keys and Credentials"
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <img src="https://via.placeholder.com/600x400" width={'100%'} style={{ borderRadius: '10px', marginBottom: '1rem' }} />
                                        <Typography variant='body1'>
                                            2. You can now see "API Keys and Credentials"
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Container>
            </Box>
        </React.Fragment >
    );
};

export default DevDocumentationStaticPage;