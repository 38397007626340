import { Avatar, Box, Button, Collapse, Container, Grid, IconButton, InputAdornment, Link, List, ListItem, ListItemButton, ListItemText, Paper, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { pesaswapLogoOnly } from '../../assets/images';
import { convenientImage, costeffectiveImage, stockImage1, timelyImage } from '../../assets/static/images';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/contact.scss';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';

const styles = theme => ({
    list: {
        padding: 0
    },
    item: {
        padding: 0
    }
});

const FaqsStaticPage = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);
    const matches479 = useMediaQuery(`(max-width:479px)`);

    React.useEffect(() => {
        setTimeout(() => {
            document.querySelector('main').style.opacity = 1;
            document.querySelector('footer').style.opacity = 1;
            document.querySelector('main').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            document.querySelector('footer').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            var sectionElements = document.querySelectorAll("section");
            for (var index = 0; index < sectionElements.length; index++) {
                sectionElements[index].style.opacity = 1;
            }
            window.scrollTo(0, 0);
        }, 1000);
    }, []);

    const [searchTerm, setSearchTerm] = React.useState("");
    const [faqItemOpen, setFaqItemOpen] = React.useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
    });

    const faqBase = [
        {
            question: 'Q1. What payment options are available on PesaSwap?',
            answer: 'Mobile(MPESA/Airtel), Card payments(Visa/Mastercard/UnionPay), Bank Transfers(currently in UAT)'
        }, {
            question: "Q2. Who's the custodian bank?",
            answer: 'We can work with any back of choice in Kenya dependent on merchant needs or we can work with our banking partners.'
        }, {
            question: 'Q3. How long do settlements take?',
            answer: 'Mobile payments: Instant: Card payments t+2, Bank payments (dependent on agreements)'
        }, {
            question: 'Q4. What kind of reports do we get? How are these delivered?',
            answer: 'The reports are available out of the box on all payments, balance plus who has transacted. We also have an API for transaction history.'
        }, {
            question: 'Q5. What currencies are supported? What currency would payouts be in? Can you hold funds in different currencies?',
            answer: 'We currently work with KES with the banks having an option of supporting other currencies.'
        }, {
            question: 'Q6. What are the system level success rates for payments? What are the most common failure reasons?',
            answer: 'We have a high success rate at 99.9% stability since January 2021. The most common failure, if it arises, is from processors downtime during upgrades or other reasons for example MPESA/Airtel.'
        }, {
            question: 'Q7. How many products can be supported on PesaSwap? Does it support subscriptions? Payment links for one-off situations?',
            answer: 'Yes and our API is extensible to work with SMS + hyperlink to support payment requests based on a schedule.'
        }, {
            question: 'Q8. Any functionality on discount/promo codes, partial payments etc?',
            answer: 'Yes, we have a discount/promo code feature on the platform that can be tracked, from start to end date.'
        }, {
            question: 'Q9. Is there a payout functionality?',
            answer: 'Yes: Using MPESA/Airtel. Bank to Bank(UAT)'
        }, {
            question: 'Q10. What certification does PesaSwap have? Any security or ISO audits?',
            answer: 'PESASWAP is PCIDSS certified.'
        }
    ];

    const [faqs, setFaqs] = React.useState(faqBase);

    const handleToggleMenuItem = (name) => {
        let menuItemOpenCpy = Object.assign({}, faqItemOpen);
        // Object.keys(menuItemOpenCpy).forEach((v) => {
        //     menuItemOpenCpy[v] = false;
        // });
        setFaqItemOpen({ ...menuItemOpenCpy, [name]: !faqItemOpen[name] });
    }

    const handleSearchFaq = (e) => {
        const { value } = e.target;
        setSearchTerm(value);
    }

    useEffect(() => {
        const tmpFaqs = [].concat(faqBase);

        const result = tmpFaqs.filter(x => {
            const term = searchTerm.toLowerCase();
            const question = x.question.toLowerCase();
            const answer = x.answer.toLowerCase();
            return question.includes(term) || answer.includes(term);
        });

        setFaqs(result);

    }, [searchTerm]);

    return (
        <React.Fragment>
            <Box component="main" sx={{ pt: matches767 ? 8 : 9, pb: 2.25, width: '100%' }} >
                <Container disableGutters sx={{ py: 1, height: '500px', px: matches479 ? 1 : 0, backgroundColor: 'rgba(225, 237, 238, .3)', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }} maxWidth={false}>
                    <Box display={'flex'} sx={{ width: '100%', paddingTop: '113px', textAlign: 'center', width: '676px', flexDirection: 'column', margin: '0 auto' }}>
                        <Typography sx={{ fontSize: '43px', fontWeight: 700, color: '#02555b' }}> Get Quick Answers</Typography>
                        <Box sx={{ paddingTop: '32px' }}>
                            <TextField onChange={handleSearchFaq} value={searchTerm} autoFocus id="outlined-basic" variant="outlined" fullWidth sx={{
                                backgroundColor: "#fff", borderRadius: '30px', width: "100%", marginTop: '14px', "& .MuiOutlinedInput-root": {
                                    "& > fieldset": {
                                        borderColor: "#f4f4f4"
                                    },
                                    "&:hover > fieldset": {
                                        borderColor: "#f4f4f4"
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#A1A1A1"
                                    }
                                }, "& input::placeholder": {
                                    fontSize: "28px",
                                    paddingLeft: '20px',
                                    alignItems: 'center'
                                }
                            }}
                                InputProps={{
                                    sx: { borderRadius: '30px', borderColor: '#fff' },
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon fontSize="large" />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                                placeholder="Search"
                                size="large" />
                        </Box>
                    </Box>
                </Container>
                <Container disableGutters sx={{ paddingBottom: '146px' }}>
                    <Box display={'flex'} sx={{ width: '100%', paddingTop: '2rem', paddingLeft: matches479 ? 4 : '125px', boxSizing: 'border-box' }}>
                        <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                            <Typography sx={{ fontSize: '2.5rem', letterSpacing: '-0.1px', fontWeight: 700, color: '#02555b' }}>General</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', paddingLeft: matches479 ? 1 : matches767 ? 6 : '125px', paddingRight: matches479 ? 1 : matches767 ? 6 : '125px', width: '100%', boxSizing: 'border-box', marginTop: '1.5rem' }}>
                        <List classes={{ root: styles.list }} sx={{ width: '100%', padding: '0px!important' /*pl: matches479 ? '0px!important' : '1.25rem' */ }}>
                            {faqs.map((v, i) =>
                                <ListItem key={i} classes={{ root: styles.item }} className="accordion1_component" dense /*sx={{borderBottom: "2px solid rgba(2, 85, 91, .3)", paddingBottom: faqItemOpen[i] ? '21px' : '36px', marginTop: i > 0 && '36.5px'  }}*/>
                                    <ListItemButton onClick={() => { handleToggleMenuItem(i) }} disableRipple sx={{
                                        px: 0,
                                        "&.MuiButtonBase-root:hover": {
                                            bgcolor: "transparent"
                                        }
                                    }}>
                                        <ListItemText
                                            primary={
                                                <Box className='accordion1_top' sx={{ display: 'flex', alignItems: 'baseline', gap: '20px' }}>
                                                    <div class="text-size-medium">{/* Q{i + 1}.  */}{v.question}</div>
                                                    {/* <Typography sx={{ fontSize: matches479 ? '1.1rem' : '23px', fontWeight: 700 }}>Q{i + 1}.</Typography>
                                                    <Typography sx={{ fontSize: matches479 ? '1.1rem' : '23px', fontWeight: 500, letterSpacing: '.4px' }}>{v.question}</Typography> */}
                                                    <img src="images/icon_chevron.svg" loading="lazy" alt="" class="accordion1_icon" />
                                                </Box>
                                            }
                                            secondary={
                                                <Collapse in={faqItemOpen[i]} timeout={400} unmountOnExit>
                                                    <div class="accordion1_bottom">
                                                        <div class="margin-bottom margin-small">
                                                            <p class="text-weight-light" style={{ color: "#000", fontSize: "1rem", lineHeight: "1.5" }}>{v.answer}</p>
                                                        </div>
                                                    </div>
                                                    {/*  <List component="div" disablePadding dense sx={{ paddingLeft: '38px', width: '100%', maxWidth: '800px', marginTop: '6px' }}>
                                                                    <ListItem>
                                                                        <ListItemText primaryTypographyProps={{ variant: "body1", fontWeight: 300, fontSize: matches479 ? '1.1rem' : '20px', lineHeight: 1.4, letterSpacing: '-0.02px' }}
                                                                            primary={v.answer} />
                                                                    </ListItem>
                                                                </List> */}
                                                </Collapse>
                                            }
                                        />
                                        {/* {faqItemOpen[i] ? <KeyboardArrowRightIcon sx={{ paddingTop: '6px', fontSize: '40px', alignSelf: 'flex-start' }} /> : <KeyboardArrowDownIcon sx={{ paddingTop: '6px', fontSize: '40px', alignSelf: 'flex-start' }} />} */}
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </List>
                    </Box>

                    {/* <section>
                        <div class="padding-global">
                            <div class="container-large">
                                <div class="padding-section-large">


                                    <div class="accordion1_component">
                                        <div data-w-id="7c763b9f-a8f2-47cf-b9bd-a3d9ed86446a" class="accordion1_top">
                                            <div class="text-size-medium">Q1. What payment options are available on Pesaswap?</div><img src="images/icon_chevron.svg" loading="lazy" alt="" class="accordion1_icon" />
                                        </div>
                                        <div style={{ height: "0px" }} class="accordion1_bottom">
                                            <div class="margin-bottom margin-small">
                                                <p class="text-weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion1_component">
                                        <div data-w-id="7c763b9f-a8f2-47cf-b9bd-a3d9ed86446a" class="accordion1_top">
                                            <div class="text-size-medium">Q1. What payment options are available on Pesaswap?</div><img src="images/icon_chevron.svg" loading="lazy" alt="" class="accordion1_icon" />
                                        </div>
                                        <div style={{ height: "0px" }} class="accordion1_bottom">
                                            <div class="margin-bottom margin-small">
                                                <p class="text-weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion1_component">
                                        <div data-w-id="7c763b9f-a8f2-47cf-b9bd-a3d9ed86446a" class="accordion1_top">
                                            <div class="text-size-medium">Q1. What payment options are available on Pesaswap?</div><img src="images/icon_chevron.svg" loading="lazy" alt="" class="accordion1_icon" />
                                        </div>
                                        <div style={{ height: "0px" }} class="accordion1_bottom">
                                            <div class="margin-bottom margin-small">
                                                <p class="text-weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                </Container>

                <Container disableGutters sx={{ backgroundColor: 'rgba(225, 237, 238, .3)', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }} maxWidth={false}>
                    <Box display={'flex'} sx={{ width: '100%', paddingTop: '113px', textAlign: 'center', width: '676px', flexDirection: 'column', margin: '0 auto', paddingTop: '68px', paddingBottom: '93px', width: '750px' }}>
                        <Typography sx={{ fontSize: '48px', fontWeight: 700, letterSpacing: '-0.5px', paddingBottom: "2rem" }}> Still Need More Answers?</Typography>
                        {/* <Typography sx={{ fontSize: '23px', paddingTop: '8px', letterSpacing: '-0.7px' }}> Lorem Ipsum bahelor, culio pareta et al mon du fin kalo casa la poilonre.</Typography> */}
                        <div className="af-class-button-group af-class-is-center">
                            <Link component={RouterLink} to="/contact-us" className="af-class-button w-button">Contact Us</Link>
                        </div>
                    </Box>
                </Container>
            </Box>
        </React.Fragment >
    );
};

export default FaqsStaticPage;