import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';

const ReconciliationApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Reconciliation</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Reconciliation API</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Other than automated throwing of callback response, we also have a manual trigger via API call called Reconciliation API.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect into Pesaswap Reconciliation API?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: You needed the API and Consumer Key.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Request
                                </Typography>
                                <Typography variant='body1' pt={1}>To submit reconciliation you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap.azurewebsites.net/api/reconcile-transaction'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://www.pesaswap.com/api/reconcile-transaction'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Request'}
                                    content={`
$url = BASE URL;
$dataArray = array('consumer_key'=>'',
'api_key'=>'',
'transaction_external_id'=>''
);

$ch = curl_init();
$data = http_build_query($dataArray);
$getUrl = $url."?".$data;
curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, FALSE);
curl_setopt($ch, CURLOPT_FOLLOWLOCATION, TRUE);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, TRUE);
curl_setopt($ch, CURLOPT_URL, $getUrl);
curl_setopt($ch, CURLOPT_TIMEOUT, 80);
$response = curl_exec($ch);
                                    `}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response'}
                                    content={{ "success": "Callback has been sent successfully." }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Error Response'}
                                    content={{ "error": "Invalid Credentials. Please Check your consumer_key and api_key." }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
           <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Reconciliation API</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect into Pesaswap Reconciliation API?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default ReconciliationApiDocumentation;
