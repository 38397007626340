import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../styles/contact.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DevDocumentationBody = ({ title, headers, values }) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Box>
            <Typography variant='subtitle1' pb={2}>{title}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            headers.map((v, i) => <TableCell key={i} sx={{ padding: '16px !important' }}>{v}</TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        values.map((v, i) =>
                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {
                                    (v || []).map((c, k) => <TableCell key={k} scope="row" sx={{ padding: '16px !important' }}>{c}</TableCell>)
                                }
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </Box>
    )
}

export default DevDocumentationBody;