import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationNote from '../../../containers/staticsite/dev-documentation-note';
import DevDocumentationContactUsLink from '../../../containers/staticsite/dev-documentation-contact-link';

const SendPaymentApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Payment Request</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Also known as a request for payment, is a usual request made by a company, individual, or any business sector for approval of payment for goods or services. It is often used for purchases when an invoice is not provided.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            If you needed a utility software that will send an invoice or payment request via email or phone number, Pesawap Payment Request API is the perfect choice for you. This tool is very easy to use. Fully powered RESTFul API that is compatible for WebApp, WebForms(Desktop) and even Mobile App. Payment method is user-friendly. 1.) Create a request via Pesaswap Payment Request API 2.) Customer or Payor will receive payment infos and a link to our Payment IFrame to their email or phone number. 3.) Using the link we sent, customer will now pay using Lipa na Mpesa Express , B2C / Paybill or even via Credit Card.
                        </Typography>
                        <br />
                        <Typography variant='body1'>
                            The Pesaswap Payment Request API has sandbox environment which you can request from Pesaswap Administrator for testing purposes. sandbox environment serves as a simulation of how API's work in production. Sandbox and Production keys are two different keys
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect into Pesaswap Payment Request API?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: You needed the API and Consumer Key as well as the Paybill Description.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DevDocumentationNote title="Keys">
                            <Typography variant='body2'>
                                Consumer and API Key, These are the keys to be used by merchants to connect into Pesaswap Payment Request API. It should be included in the request body to determine if merchant has valid access to our API.
                            </Typography>
                        </DevDocumentationNote>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Send Payment Request</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Payment Request
                                </Typography>
                                <Typography variant='body1' pt={1}>To submit payment request you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap.azurewebsites.net/api/payment/request'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://www.pesaswap.com/api/payment/request'} />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Body Request Parameters"
                                    headers={['Field', 'Description', 'Additional']}
                                    values={[
                                        ['consumer_key', <DevDocumentationContactUsLink statement='This serves as an api username. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['api_key', <DevDocumentationContactUsLink statement='This serves as an api user password. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['paybill_description', <DevDocumentationContactUsLink statement='Every api must have an admin settings. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['description', 'Transaction description. IE. Payment for goods.', 'Required'],
                                        ['range', '1 - One time payment. Also known as instant payment method. Once triggered, payment request will be received instantly by the customer or payor through their email or phone number.', 'Required'],
                                        ['billing_date', 'Ideally transaction current date. format: mm/dd/yyyy. IE. 10/23/2019', 'Required'],
                                        ['last_billing_date', 'Ideally transaction current date. format: mm/dd/yyyy. IE. 10/23/2019', 'Required'],
                                        ['external_id', `Customer external_id. This must be generated on api user's end and must be included on Pesaswap Create Customer API Call. Note: If this is provided, phone is no longer needed(exclude in the parameter). You can send request to multiple customers by using comma(,) as delimiter. IE. customer_external_id#1, customer_external_id#2. It means you are sending the payment request to two(2) different customer.`, ''],
                                        ['phone_number', `Assuming you don't have customer's yet on Pesaswap End.You can still send Payment Request through phone numbers(Maximum of 9). Note: You must exclude the external_id to let the pesaswap api know that you will send a request to their phones. You can also send request to multiple phones by using comma(,) as delimiter. IE. 254708374148, 254708374149. It means you are sending the payment request to two(2) different phones.`, ''],
                                        ['total_amount', 'Amount to be paid.', 'Required'],
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request - Multiple Customer'}
                                    content={`
$url = BASE URL;
$curl_init();
curl_setopt($curl, CURLOPT_URL, $url); curl_setopt($curl, CURLOPT_HTTPHEADER, array('Content-Type:application/json'));

$curl_post_data = array(
'consumer_key' => '',
'api_key' => '',
'paybill_description' => '',
'description' => '',
'range' => '1',
'external_id' => 'external_id#1,external_id#2',
'billing_date' => '10/23/2019',
'last_billing_date' => '10/23/2019',
'total_amount' => '' );

$data_string = json_encode($curl_post_data);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_POST, true);
curl_setopt($curl, CURLOPT_POSTFIELDS, $data_string);
$curl_response = curl_exec($curl);
$response = json_decode($curl_response,true);
                                    `}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response - Multiple Customer'}
                                    content={"Payment Request has been created successfully to customer."}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request - Multiple Phone Number'}
                                    content={`
$url = BASE URL;
$curl_init();
curl_setopt($curl, CURLOPT_URL, $url); curl_setopt($curl, CURLOPT_HTTPHEADER, array('Content-Type:application/json'));

$curl_post_data = array(
'consumer_key' => '',
'api_key' => '',
'paybill_description' => '',
'description' => '',
'range' => '1',
'phone_number' => '254708374148,254708374149',
'billing_date' => '10/23/2019',
'last_billing_date' => '10/23/2019',
'total_amount' => '' );

$data_string = json_encode($curl_post_data);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_POST, true);
curl_setopt($curl, CURLOPT_POSTFIELDS, $data_string);
$curl_response = curl_exec($curl);
$response = json_decode($curl_response,true);
                                    `}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response - Multiple Phone Number'}
                                    content={"Payment Request has been successfully sent to phone number."}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Error Response'}
                                    content={`
- An error will be shown if parameter phone_number is empty for multiple phone api call. => Phone number is required.

- An error will be shown if parameter external_id is not exist for multiple customer api call. => external_id does not exist:750719F0-9364-11E9-B2DD-5399F21EC503s

- An error will be shown if parameter total_amount is not a whole number. => Invalid total_amount. Please enter whole number.
                                    `}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Payment Request</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect into Pesaswap Payment Request API?</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>Send Payment Request</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default SendPaymentApiDocumentation;
