import { Box, Button, Container, Grid, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/static/contact-us.scss';
import axios from 'axios';

const PrivacyPolicyStaticPage = () => {
    return (
        <React.Fragment>
            <Box component="main" sx={{ pt: 3, pb: 2.25, width: '100%' }}>
                <Container disableGutters sx={{ py: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }} maxWidth={false}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', textAlign: 'center', gap: '26px', marginTop: '88px' }}>
                        <Box sx={{}}>
                            <Typography color="#02555b" sx={{ fontSize: '64px', fontWeight: 900, letterSpacing: '-1px', lineHeight: 1.3 }}>
                                Privacy Policy
                            </Typography>
                        </Box>
                    </Box>
                </Container>
                <Container disableGutters maxWidth={false} sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', pt: 8 }}>
                    <Box display={'flex'} flexDirection={'column'} width="100%" py={'41px'} sx={{ gap: '26px', paddingLeft: '125px', paddingRight: '39px' }} className="form-post-container">
                        <Box sx={{ display: 'block', textAlign: 'left', paddingRight: '39px' }} className="form-label-field">
                            <Typography variant='h6' pb={1}>SCOPE</Typography>
                            <Typography variant='body1' sx={{ lineHeight: 1.8}}>This policy applies to all users and other third parties, if they provide network access.</Typography>
                            <Typography variant='body1' sx={{ lineHeight: 1.8}}>The term Information Systems defines the total environment and includes, but is not limited to, all documentation, physical and logical controls, personnel, hardware (e.g. desktop, network devices, and wireless devices), software and information.</Typography>
                            <Typography variant='body1' sx={{ lineHeight: 1.8}}>All users are required to read, understand and comply with other Information Security policies, standards and procedures. If any user does not fully understand anything in these documents, he/she should contact the Information Security Manager or the concerned department/division units to jointly resolve any conflicts arising from this policy.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>PURPOSE</Typography>
                            <Typography variant='body1'>The principles of security adopted by the company are:</Typography>
                            <Typography variant='body1'>Confidentiality: information should be accessible only to authorized personnel</Typography>
                            <Typography variant='body1'>Integrity: information should be modifiable only by authorized personnel </Typography>
                            <Typography variant='body1'>Availability: information should be made available to personnel who need it</Typography>
                            <Typography variant='body1'>The purpose is to protect the confidential information of the company, its clients, as well as information received from users to minimize business damage, to ensure business continuity and gain customer confidence by undertaking proactive measures for preventing and minimizing the impact of security incidents and disasters.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>POLICY STATEMENT</Typography>
                            <Typography variant='body1'>To protect its infrastructure and information generated/processed within or received from its clients by building robust information systems and processes. Employees, contractors, vendors and stake holders shall be committed to comply with all legal, regulatory and contractual obligations by adhering to the practices defined to protect business sensitive, client and operational information.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>GENERAL RESPONSIBILITY</Typography>
                            <Typography variant='body1'>It is the responsibility of all employees, and contractors to comply with this and other associated policies. The management is responsible to review, update, maintain, and disseminate a security policy annually and as required.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>POLICY &ndash; IT AND INFORMATION SECURITY</Typography>
                            <Typography variant='body1'>The responsibility is to manage the IT functions with the help of the associated third party service provider and provide adequate protection and confidentiality to all the data of company and proprietary software systems, whether held centrally, on local storage media, or remotely, to ensure the continued availability of data and programs to all authorized members and to ensure the integrity of all data and configuration controls is maintained.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>INFORMATION SECURITY OBJECTIVE</Typography>
                            <Typography variant='body1'>The objectives of implementing an information security management system are:</Typography>
                            <Typography variant='body1'>To ensure adequate protection of all data and data processing facilities.</Typography>
                            <Typography variant='body1'>To ensure effective management along with third party support, where required.</Typography>
                            <Typography variant='body1'>To ensure formal assignment of information security responsibilities to a Chief Security Officer or other security-knowledgeable member of management.</Typography>
                            <Typography variant='body1'>To establish, document and distribute all the security policies and procedures to responsible personnel.</Typography>
                            <Typography variant='body1'>To ensure that all information security accidents or suspected security flaws have appropriate reporting mechanisms so that superiors are notified, and these incidents are appropriately investigated, analysed and handled.</Typography>
                            <Typography variant='body1'>To provide a safe and secure information systems working environment for all staff and contractors by conducting information security awareness program.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>RISK MANAGEMENT FRAMEWORK</Typography>
                            <Typography variant='body1'>In order to manage information security, the organization will adopt an asset-based risk approach.</Typography>
                            <Typography variant='body1'>This approach mandates:</Typography>
                            <Typography variant='body1'>Identify business functions within the scope</Typography>
                            <Typography variant='body1'>Identify all the information assets used by the business functions such as:</Typography>
                            <Typography variant='body1'>System/Network/Security/Laptops/Desktops/Backup Devices etc.;</Typography>
                            <Typography variant='body1'>Policies/Procedures/Other departmental documents and records/ client specific data/ financial information etc.;</Typography>
                            <Typography variant='body1'>Software/Application, Logs etc.;</Typography>
                            <Typography variant='body1'>People;</Typography>
                            <Typography variant='body1'>Vendors;</Typography>
                            <Typography variant='body1'>Against each asset, list the owner of the asset in the organization</Typography>
                            <Typography variant='body1'>The assets with similar threats and vulnerabilities have been grouped together in the Risk register.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>SECURITY AWARENESS PROGRAM</Typography>
                            <Typography variant='body1'>An information security management system will continue to grow and maintain itself only if the people of the organization are continuously vigilant and are able to absorb information security principles in their work culture.</Typography>
                            <Typography variant='body1'>In accordance with this statement, it is essential for the company to implement security awareness initiatives at all levels of the organization, including senior management, middle management, team leaders, heads of department, support staff, and any third parties.</Typography>
                            <Typography variant='body1'>The information security awareness sessions will be an ongoing initiative which will ensure that all the employees and contractors are aware of the information security policies that are relevant to them. In addition, all the procedures, guidelines, and information security best practices in conjunction with other laws, regulations, and management best practices as adopted by the company.</Typography>
                            <Typography variant='body1'>All employees will go through employee information security awareness program when they join the organization as part of induction, additionally, an annual refresher trainings and / or quiz would be conducted online or in classroom to enforce the awareness.</Typography>
                            <Box pb={4}></Box>
                            <Typography variant='h6' pb={1}>CONTACT WITH SPECIAL INTEREST GROUPS</Typography>
                            <Typography variant='body1'>Appropriate contacts with special interest groups or other specialist security forums and professional associations should be maintained.</Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
};

export default PrivacyPolicyStaticPage;