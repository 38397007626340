import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter, Switch, Route } from 'react-router-dom';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import AppRoutes from './components/appRoutes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import staticRoutes from './routes/static.routes';
import StaticPageLayout from './containers/layouts/static';
import loadingScripts from './static/scripts';
import loadingStyles from './static/styles';
import DevDocumentationStaticPage from './static/pages/devdocumentation';
import staticDevDocumentationRoutes from './routes/static.dev-documentation.routes';

const theme = createTheme({
  typography: {
    fontFamily: "inherit",
  },
  palette: {
    primary: {
      main: '#02555B',
    },
    secondary: {
      main: '#02555B',
    },
    error: {
      main: '#CB0B0A',
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const renderReactDom = () => {
  root.render(
    <HashRouter>
      <ThemeProvider theme={theme}>
          <Switch>
            {/* New static page layout */}
            <Route path="/:path?" exact>
              <StaticPageLayout>
                <Switch>
                  {staticRoutes.map((route) => (
                    <AppRoutes key={route.path} path={route.path} component={route.component} isPrivate={route.isPrivate} exact={route.exact} />
                  ))}
                </Switch>
              </StaticPageLayout>
            </Route>
            <Route path="/dev-documentation/:path?" exact>
              <StaticPageLayout>
                <DevDocumentationStaticPage>
                  <Switch>
                    {staticDevDocumentationRoutes.map((route) => (
                      <AppRoutes key={route.path} path={route.path} component={route.component} isPrivate={route.isPrivate} exact={route.exact} />
                    ))}
                  </Switch>
                </DevDocumentationStaticPage>
              </StaticPageLayout>
            </Route>
            {/* New static page layout */}
          </Switch>
      </ThemeProvider>
    </HashRouter>
  );
}

Promise.all([
  loadingStyles,
  loadingScripts,
]).then(() => {
  renderReactDom();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
