import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../styles/contact.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DevDocumentationCode = ({ value }) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Typography component={'span'} sx={{
            backgroundColor: 'rgba(0,0,0,0.05)',
            fontFamily: "'Source Code Pro', monospace"
        }}>
            {value}
        </Typography>
    )
}

export default DevDocumentationCode;