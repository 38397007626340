import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationNote from '../../../containers/staticsite/dev-documentation-note';

const CallbackApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Callback</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Pesaswap could send to you the transaction records you needed the moment there will be changes of the transaction status from our end. We can relay it to you using your callback URL.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Callback Setup</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            For the merchant to receive the updated data from the gateway, they need to establish the requirement below.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DevDocumentationNote title="Steps">
                            <Typography variant='body2'>
                                Step 1: Your callback URL . Ideally a public page on your webApp where the gateway can throw the updated records if there is significant changes of the transaction status. IE. from Pending to Completed, or Failed.
                            </Typography>
                            <br />
                            <Typography variant='body2'>
                                Step 2: Please inform the Pesaswap Admin so that your callback URL will be added on the platform.
                            </Typography>
                            <br />
                            <Typography variant='body2'>
                                Step 3: How to get the record? The gateway will send to you this format. [your-callback-url]?data={`{`}json - string - formatted - data{`}`} So all you to do is to grab the values of [data] variable/parameter. Typical grabbing of data in a GET request method with the parameter name of [data].
                            </Typography>
                            <br />
                            <Typography variant='body2'>
                                Step 4: Decode the data from json-string-format to your ideal data format.
                            </Typography>
                            <br />
                            <Typography variant='body2'>
                                Step 5: Expected data format to receive below.
                            </Typography>
                        </DevDocumentationNote>
                    </Grid>
                    <Grid item xs={12} paddingY={1} />
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Sample JSON String"
                                    headers={['Name', 'Sample Value']}
                                    values={[
                                        ['data', '{\"id\":636,\"business_name\":\"ABC Merchant\",\"transaction_external_id\":\"98470880-145c-11eb-993e-e9178dbfc53a\",\"transaction_id\":null,\"amount\":\"1\",\"status\":\"Failed\",\"mpesa_result_desc\":null,\"processor\":\"I&M Bank\",\"method\":\"CardPayment\",\"command_id\":null,\"customer\":\"Name Lastname\",\"send_to_callback_url\":\"https:\\/\\/devpesaswap.azurewebsites.net\\/mysite-callback.php\"}']
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingY={22} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Callback With Basic Header Auth - Expected Data to Receive</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: This will require consumer_key and api_key (base64 encoded) for the payload.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    Method: <Chip label="GET" color='info' sx={{ marginLeft: 1 }} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationHeaders
                                    title="Headers"
                                    headers={['Field', 'Value']}
                                    values={[
                                        ['Content-Type', 'application/json'],
                                        ['Authorization', '"Basic " + "base64_encode(consumer_key:api_key)"']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Details"
                                    headers={['Description', 'Value',]}
                                    values={[
                                        ['data', '{\"id\":636,\"business_name\":\"ABC Merchant\",\"transaction_external_id\":\"98470880-145c-11eb-993e-e9178dbfc53a\",\"transaction_id\":null,\"amount\":\"1\",\"status\":\"Failed\",\"mpesa_result_desc\":null,\"processor\":\"I&M Bank\",\"method\":\"CardPayment\",\"command_id\":null,\"customer\":\"Name Lastname\",\"send_to_callback_url\":\"https:\\/\\/devpesaswap.azurewebsites.net\\/mysite-callback.php\"}']
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingY={22} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Callback With Extended Header Auth - Expected Data to Receive</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: This will require consumer_key, api_key, external_id and amount (base64 encoded) for the payload. Note: amount format should be two decimal places IE. 1234.00 without commas.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    Method: <Chip label="GET" color='info' sx={{ marginLeft: 1 }} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationHeaders
                                    title="Headers"
                                    headers={['Field', 'Value']}
                                    values={[
                                        ['Content-Type', 'application/json'],
                                        ['Authorization', '"Basic " + "base64_encode(consumer_key:api_key:external_id:amount)"']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Details"
                                    headers={['Description', 'Value',]}
                                    values={[
                                        ['data', '{\"id\":636,\"business_name\":\"ABC Merchant\",\"transaction_external_id\":\"98470880-145c-11eb-993e-e9178dbfc53a\",\"transaction_id\":null,\"amount\":\"1\",\"status\":\"Failed\",\"mpesa_result_desc\":null,\"processor\":\"I&M Bank\",\"method\":\"CardPayment\",\"command_id\":null,\"customer\":\"Name Lastname\",\"send_to_callback_url\":\"https:\\/\\/devpesaswap.azurewebsites.net\\/mysite-callback.php\"}']
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
           <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Callback</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>Callback Setup</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>Callback With Basic Header Auth - Expected Data to Receive</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>Callback With Extended Header Auth - Expected Data to Receive</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default CallbackApiDocumentation;
