import React from "react";
import ApiKeyDocumentation from "../static/pages/api_documentation/api-key.documentation";
import BalanceApiDocumentation from "../static/pages/api_documentation/balance.documentation";
import CollectionApiDocumentation from "../static/pages/api_documentation/collection.documentation";
import C2bApiDocumentation from "../static/pages/api_documentation/c2b.documentation";
import DisbursementApiDocumentation from "../static/pages/api_documentation/disbursement.documentation";
import HeaderTokenizationApiDocumentation from "../static/pages/api_documentation/header-tokenization.documentation";
import TransactionApiDocumentation from "../static/pages/api_documentation/transaction-list.documentation";
import ReconciliationApiDocumentation from "../static/pages/api_documentation/reconciliation.documentation";
import RefundApiDocumentation from "../static/pages/api_documentation/refund.documentation";
import SendPaymentApiDocumentation from "../static/pages/api_documentation/send-payment.documentation";
import RecurringBillingApiDocumentation from "../static/pages/api_documentation/recurring-billing.documentation";
import WeeklyRecurringBillingApiDocumentation from "../static/pages/api_documentation/recurring-billing-weekly.documentation";
import MonthlyRecurringBillingApiDocumentation from "../static/pages/api_documentation/recurring-billing-monthly.documentation";
import QuaterlyRecurringBillingApiDocumentation from "../static/pages/api_documentation/recurring-billing-quarterly.documentation";
import SemiAnnuallyRecurringBillingApiDocumentation from "../static/pages/api_documentation/recurring-billing-semiannually.documentation";
import AnnuallyRecurringBillingApiDocumentation from "../static/pages/api_documentation/recurring-billing-annually.documentation";
import TermsRecurringBillingApiDocumentation from "../static/pages/api_documentation/recurring-billing-terms.documentation";
import AirtelApiDocumentation from "../static/pages/api_documentation/wallet-airtel.documentation";
import WalletBalanceApiDocumentation from "../static/pages/api_documentation/wallet-balance.documentation";
import WalletCheckoutPageApiDocumentation from "../static/pages/api_documentation/wallet-checkout.documentation";
import CheckoutTokenizationApiDocumentation from "../static/pages/api_documentation/checkout-tokenization.documentation";
import WalletMpesaApiDocumentation from "../static/pages/api_documentation/wallet-mpesa.documentation";
import WalletRefundApiDocumentation from "../static/pages/api_documentation/wallet-refund.documentation";
import WalletTransactionApiDocumentation from "../static/pages/api_documentation/wallet-tranasaction.documentation";
import WoocommerceApiDocumentation from "../static/pages/api_documentation/woocommerce.documentation";
import CheckoutPageApiDocumentation from "../static/pages/api_documentation/checkout.documentation";
import CardPaymentApiDocumentation from "../static/pages/api_documentation/card-payment.documentation";
import CallbackApiDocumentation from "../static/pages/api_documentation/callback.documentation";
import DisbursementMpesaApiDocumentation from "../static/pages/api_documentation/disbursement-mpesa.documentation";

const staticDevDocumentationRoutes = [
    {
        path: '/dev-documentation/api-keys',
        exact: true,
        component: ApiKeyDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/mpesa-balance',
        exact: true,
        component: BalanceApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/collection-api',
        exact: true,
        component: CollectionApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/c2b-collection-api',
        exact: true,
        component: C2bApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/disbursement-api',
        exact: true,
        component: DisbursementApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/header-tokenization',
        exact: true,
        component: HeaderTokenizationApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/transaction',
        exact: true,
        component: TransactionApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/reconciliation-api',
        exact: true,
        component: ReconciliationApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/refund-api',
        exact: true,
        component: RefundApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/send-payment',
        exact: true,
        component: SendPaymentApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/recurring-billing',
        exact: true,
        component: RecurringBillingApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/weekly-recurring-billing',
        exact: true,
        component: WeeklyRecurringBillingApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/monthly-recurring-billing',
        exact: true,
        component: MonthlyRecurringBillingApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/quarterly-recurring-billing',
        exact: true,
        component: QuaterlyRecurringBillingApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/semi-annually-recurring-billing',
        exact: true,
        component: SemiAnnuallyRecurringBillingApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/annually-recurring-billing',
        exact: true,
        component: AnnuallyRecurringBillingApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/terms-recurring-billing',
        exact: true,
        component: TermsRecurringBillingApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/wallet-airtel',
        exact: true,
        component: AirtelApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/wallet-balance',
        exact: true,
        component: WalletBalanceApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/wallet-checkout',
        exact: true,
        component: WalletCheckoutPageApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/checkout-tokenization',
        exact: true,
        component: CheckoutTokenizationApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/wallet-mpesa',
        exact: true,
        component: WalletMpesaApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/wallet-refund',
        exact: true,
        component: WalletRefundApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/wallet-transaction',
        exact: true,
        component: WalletTransactionApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/woocommerce',
        exact: true,
        component: WoocommerceApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/checkout',
        exact: true,
        component: CheckoutPageApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/card-payment',
        exact: true,
        component: CardPaymentApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/callback',
        exact: true,
        component: CallbackApiDocumentation,
        isPrivate: false
    },
    {
        path: '/dev-documentation/disbursement-mpesa',
        exact: true,
        component: DisbursementMpesaApiDocumentation,
        isPrivate: false
    },
];

export default staticDevDocumentationRoutes;