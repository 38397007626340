import { Box, Link } from '@mui/material';
import React from 'react';
import '../../styles/contact.scss';
import { useHistory } from 'react-router-dom';

const DevDocumentationContactUsLink = ({ statement }) => {
    const history = useHistory()

    const proc_statement = statement.split('contact us')

    return (
        <React.Fragment>
            {proc_statement?.[0]}
            < Link
                underline='always'
                component={'button'}
                onClick={() => {
                    history.push('/contact-us')
                }}
                sx={{ color: '#02555b !important', fontWeight: '700 !important' }}
            >
                contact us
            </Link>
            {proc_statement?.[1]}
        </React.Fragment>
    )
}

export default DevDocumentationContactUsLink;