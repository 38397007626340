import { Avatar, Box, Button, Container, Grid, Link, Paper, TextField, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { pesaswapLogoOnly } from '../../assets/images';
import { convenientImage, costeffectiveImage, stockImage1, timelyImage } from '../../assets/static/images';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/contact.scss';
import CheckIcon from '@mui/icons-material/Check';
import { airtelPaymentLogo, mastercardPaymentLogo, mpesaPaymentLogo, pesaswapLogo, visaPaymentLogo } from '../../assets/images';
import { Link as RouterLink } from 'react-router-dom';

const PaymentsStaticPage = () => {
    React.useEffect(() => {
        setTimeout(() => {
            document.querySelector('main').style.opacity = 1;
            document.querySelector('footer').style.opacity = 1;
            document.querySelector('main').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            document.querySelector('footer').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            var sectionElements = document.querySelectorAll("section");
            for (var index = 0; index < sectionElements.length; index++) {
                sectionElements[index].style.opacity = 1;
            }
            window.scrollTo(0, 0);
        }, 1000);
    }, []);

    return (
        <React.Fragment>
            <section id="paymentsx" data-w-id="2574d8fb-155d-fd5d-b76a-6d007ccf5ded" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_layout">
                <div className="af-class-padding-global">
                    <div className="af-class-container-large">
                        <div className="af-class-padding-section-head">
                            <div className="w-layout-grid af-class-layout_component">
                                <div className="af-class-layout_content">
                                    <div className="af-class-margin-bottom af-class-margin-medium">
                                        <h3 className="af-class-title-head-section">Payments</h3>
                                    </div>
                                    <div className="af-class-margin-bottom af-class-margin-medium">
                                        <p className="af-class-text-size-medium af-class-subtitle-head-section">Whether you&rsquo;re selling online, collecting payments or getting paid, Pesaswap is your best option for handling digital payments in East Africa.</p>
                                    </div>
                                    <div className="af-class-margin-20-0">
                                        <div className="af-class-button-group">
                                            <a href={`${window.location.origin}/v2/merchant-registration`} className="af-class-button w-button">Get Started</a>
                                            {/* <div className="af-class-margin-left-wrapper">
                                                <a href="#reconcile" className="af-class-button af-class-is-link w-button">Learn More</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div id="w-node-_2574d8fb-155d-fd5d-b76a-6d007ccf5dfe-b5133c56" className="af-class-layout_image-wrapper" style={{ display: 'flex' }}>
                                    <img src="/images/Pesaswap-Logo.svg" loading="lazy" sizes="(max-width: 991px) 90vw, 41vw" srcSet="/images/Pesaswap-Logo.svg 500w, /images/Pesaswap-Logo.svg 800w, /images/Pesaswap-Logo.svg 1080w, /images/Pesaswap-Logo.svg 1600w" alt className="af-class-layout_imagex" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-w-id="ae67c618-b9b8-b229-9df2-822271018b37" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_cta">
                <div className="af-class-padding-global">
                    <div className="af-class-container-large">
                        <div className="af-class-padding-section-large">
                            <div className="w-layout-grid af-class-cta51_component">
                                <div className="af-class-cta51_card">
                                    <div className="af-class-text-align-center">
                                        <div className="af-class-max-width-large">
                                            <div className="af-class-margin-bottom af-class-margin-small">
                                                <h2 className="af-class-cta_title"><strong>Start receiving and making digital payments with Pesaswap.</strong></h2>
                                            </div>
                                            <p className="af-class-text-size-medium">Create your free account in minutes and join the many businesses using Pesaswap.</p>
                                        </div>
                                    </div>
                                    <div className="af-class-margin-top af-class-margin-medium">
                                        <div className="af-class-button-group af-class-is-center">
                                            <a href={`${window.location.origin}/v2/merchant-registration`} className="af-class-button af-class-is-alternate w-button">Get Started</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default PaymentsStaticPage;