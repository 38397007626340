import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationNote from '../../../containers/staticsite/dev-documentation-note';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import sampleIframeDownload from '../../../downloadables/checkout-page/cybersource-chekout-page.zip'
import DevDocumentationDownloadLink from '../../../containers/staticsite/dev-documentation-download-link';
import DevDocumentationCheckoutTokenLink from '../../../containers/staticsite/dev-documentation-checkout-token-link';
import checkoutPageDownload from '../../../downloadables/woo-plugin/checkout-page.zip'
import DevDocumentationImage from '../../../containers/staticsite/dev-documentation-image';


const CheckoutPageApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', paddingLeft: matches767 ? 0 : '5%', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                    <Box display={'flex'} sx={{ flexDirection: 'column', textAlign: 'center' }}>
                        <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Checkout Pages</Typography>
                    </Box>
                </Box>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Checkout Page with Cybersource</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Another secured payment checkout page is via cybersource. Please see details below.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect and setup the Card Payment Page with Cybersource?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: Login to production site or sandbox site portal as merchant.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DevDocumentationNote title="Keys">
                            <Typography variant='body2'>
                                - To view the merchant_identifier, click the user icon  on upper right menu and select "API Key and Credentials".
                            </Typography>
                            <Typography variant='caption'>
                                Note: Merchant needs to submit all the required documents first for KYC purposes before it can access the merchant_identifier.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant='body2'>
                                - homePageUrl - Also known as landing or redirection page when you click the "Refresh Page" button after cybersource payment processes has been done. It just redirect to your ideal page.
                            </Typography>
                            <br />
                            <Typography variant='body2'>
                                - Create your own checkout page. How to do it? Please download the <DevDocumentationDownloadLink label='sample Iframe Code here.' document_title={'Sample IFrame Code'} url={sampleIframeDownload} /> Replace the value of merchant_identifier and homePageUrl. You can design your own css for iframe as well.
                            </Typography>
                        </DevDocumentationNote>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography variant='subtitle1' pb={2}>Sample Checkout Page Via Cybersource Using an Iframe</Typography>
                        <iframe id="pesa-payment-cybersource" src="https://devpesaswap.azurewebsites.net/cybersource/payment-info?merchant_identifier=cSxDZHXjDaP5fAVmYtPo1immn" width="100%" height="640px">
                        </iframe>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    Base URL
                                </Typography>
                                <Typography variant='body1' pt={1}>To create the chekout URL use the following base URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap.azurewebsites.net/cybersource/payment-info'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://www.pesaswap.com/checkout/cybersource/payment-info'} />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Query String Parameters"
                                    headers={['Field', 'Description/Value', 'Additional']}
                                    values={[
                                        ['merchant_identifier', '<your merchant identifier>', 'Required'],
                                        ['transaction_external_id', 'Prefer GUID/UUID. Serves as a primary key for transaction and will be used for record integration, from Pesaswap to user’s end.', ''],
                                        ['customer_external_id', 'Must be GUID/UUID. Though it is optional, we strongly suggest to include this on api call for you to monitor the payment from your specific customers.', '']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Complete Iframe src URL'}
                                    content={`
- Sandbox => https://devpesaswap.azurewebsites.net/cybersource/payment-info?merchant_identifier=your-cybersource-identifier&&transaction_external_id=your-transaction-external-id&&customer_external_id=your-customer-external-id

- Production => https://www.pesaswap.com/checkout/cybersource/payment-info?merchant_identifier=your-cybersource-identifier&&transaction_external_id=your-transaction-external-id&&customer_external_id=your-customer-external-id
                                    `}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingY={22} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Checkout Page with Iveri</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Pesaswap offer a more secured and hassle free Credit Card payment called the checkout page. The user can just call the checkout page on their template through iframe, supply the required parameters and use it for card payment. It can cater both 3d secured enrolled and unenrolled card. Please see details below.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect and setup the Card Payment Page with Iveri?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: You needed header token. Please refer to <DevDocumentationCheckoutTokenLink />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DevDocumentationNote title="Keys">
                            <Typography variant='body2'>
                                - Login to production site or sandbox site portal as merchant.
                            </Typography>
                            <br />
                            <Typography variant='body2'>
                                - To view the consumer and api keys, click the user icon  on upper right menu and select "API Key and Credentials".
                            </Typography>
                            <Typography variant='caption'>
                                Note: Merchant needs to submit all the required documents first for KYC purposes before it can access the API and consumer keys.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant='body2'>
                                - To view the customer_external_id:
                                <br />
                                Click [Customers] sidebar menu, use search engine if needed.
                                <br />
                                Click [eye] icon on the list.
                                <br />
                                Look for [Exernal ID] field.
                            </Typography>
                            <br />
                            <Typography variant='body2'>
                                - Generate token using tokenization API. Sample Code below.
                            </Typography>
                            <Typography variant='body2'>
                                - Create an Iframe or an HTML Iframe and supply the required parameter needed for the checkout page.
                            </Typography>
                            <Typography variant='body2'>
                                - Please see sample code below and parameter's definition.
                            </Typography>
                        </DevDocumentationNote>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    Base URL
                                </Typography>
                                <Typography variant='body1' pt={1}>To create the chekout page URL use the following base URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap.azurewebsites.net/checkout/v2'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://www.pesaswap.com/checkout/v2'} />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Query String Parameters"
                                    headers={['Field', 'Description/Value', 'Additional']}
                                    values={[
                                        ['token', 'Generated from Checkout Tokenization API', 'Required'],
                                        ['currency', 'Money currency', 'Required'],
                                        ['amount', 'Amount to be processes', 'Required'],
                                        ['transaction_external_id', 'Prefer GUID/UUID. Serves as a primary key for transaction and will be used for record integration, from Pesaswap to user’s end.', 'Required'],
                                        ['customer_external_id', 'An existing customer’s GUID/UUID saved on Pesaswap endpoint which serves as a primary key.', '"No" for 3d secure enrolled card, "Yes" for unenrolled 3d secure card and frau.net fraud manager']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Example Success Response on Checkout Page"
                                    headers={['Description', 'Value']}
                                    values={[
                                        ['Once payment has been succeeded, you will be redirected to success template.', 'Payment has been succeeded.'],
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Example Error Response on Checkout Page"
                                    headers={['Description', 'Value']}
                                    values={[
                                        ['You will be redirected to error template if something is not correct.', 'Sample message: transaction_external_id already exist!, Missing PAN., etc.'],
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={6} />
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' pb={2}>Sample HTML code</Typography>
                                <Typography variant='body1' component={'span'}>
                                    Download the <DevDocumentationDownloadLink label='sample HTML IFrame code' document_title={'Iveri Sample HTML IFrame Code'} url={checkoutPageDownload} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} paddingY={2} />
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' pb={2}>Sample Checkout Page Via Iveri Using an Iframe</Typography>
                                <iframe id="pesa-payment" src="https://devpesaswap.azurewebsites.net/checkout/v1?token=e74c8ce0-85a3-11eb-9343-d7981758a534&&currency=KES&&amount=1&&transaction_external_id=1f16ad20-c74e-11ea-9967-ab1762e28f22&&customer_external_id=8BR06UBljTetm1ZcP5Rjq9GUv" width="100%" height="640px">
                                </iframe>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingY={22} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to change the checkout page logo into your own logo?</Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={1} />
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Box>
                                    <DevDocumentationImage src="/images/api-documentation/upload-logo-ss-1.jpg" />
                                    <Typography variant='body1'>
                                        1. On sidebar menu, select Checkout Page.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <DevDocumentationImage src="/images/api-documentation/upload-logo-ss-2.jpg" />
                                    <Typography variant='body1'>
                                        2. Select Upload Logo.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <DevDocumentationImage src="/images/api-documentation/upload-logo-ss-3.jpg" />
                                    <Typography variant='body1'>
                                        3. Browse your logo and click Save.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Checkout Page with Cybersource</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect and setup the Card Payment Page with Cybersource?</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>Checkout Page with Iveri</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect and setup the Card Payment Page with Iveri?</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to change the checkout page logo into your own logo?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default CheckoutPageApiDocumentation;
