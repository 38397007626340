import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../styles/contact.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DevDocumentationResponse = ({ title, content }) => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Box>
            <Typography variant='subtitle1' pb={2}>{title}</Typography>
            <Box sx={{ backgroundColor: '#012224', borderRadius: 2, color: '#fff', px: 4, py: 3, maxHeight: 480, overflowY: 'auto' }}>
                <Typography component={'pre'} sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    <Typography component={'code'}>
                        {
                            typeof content === 'object' || Array.isArray(content)
                                ? JSON.stringify(content, undefined, 2)
                                : content
                        }
                    </Typography>
                </Typography>
            </Box>
        </Box>
    )
}

export default DevDocumentationResponse;