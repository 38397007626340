import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { pesaswapLogoOnly } from '../../assets/images';
import { convenientImage, costeffectiveImage, stockImage1, timelyImage } from '../../assets/static/images';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/contact.scss';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ParentMenuItem = ({ title, children }) => {
    const [menuItemOpen, setMenuItemOpen] = useState(false)

    return (
        <React.Fragment>
            <ListItem disablePadding style={{ paddingLeft: 0 }}>
                <ListItemButton sx={{ py: 1.5 }} onClick={() => setMenuItemOpen(!menuItemOpen)}>
                    <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={title} />
                    {menuItemOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={menuItemOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </React.Fragment>
    )
}

const DocumentationSideMenu = () => {
    const history = useHistory()
    const location = useLocation()
    const { pathname } = location

    const navigateDocumentation = (url) => {
        history.push(`/dev-documentation/${url}`)
    }

    return (
        <Paper sx={{ minWidth: 400, backgroundColor: "rgba(225, 237, 238, .3)", display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }} elevation={0} square>
            <Box sx={{ overflow: 'auto' }}>
                <List sx={{ pl: "0!important" }}>
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton selected={pathname.includes("/api-keys")} sx={{ py: 1.5 }} onClick={() => navigateDocumentation("api-keys")}>
                            <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={"API Keys"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ParentMenuItem title="Account Balance">
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/mpesa-balance")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("mpesa-balance")}>
                                <ListItemText primary="Mpesa Balance" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title={"Collection (new)"}>
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/collection-api")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("collection-api")}>
                                <ListItemText primary="Collection API" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/c2b-collection-api")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("c2b-collection-api")}>
                                <ListItemText primary="C2B API" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title={"Disbursement"}>
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/disbursement-api")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("disbursement-api")}>
                                <ListItemText primary={"Disbursement API"} />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/disbursement-mpesa")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("disbursement-mpesa")}>
                                <ListItemText primary="Mpesa V3 Disbursement" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title="Tokenizations">
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/header-tokenization")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("header-tokenization")}>
                                <ListItemText primary="Header Tokenization" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/iveri-tokenization")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("iveri-tokenization")} disabled>
                                <ListItemText primary="Iveri Tokenization" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/checkout-tokenization")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("checkout-tokenization")}>
                                <ListItemText primary="Checkout Tokenization" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title="Transaction Query API">
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/transaction")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("transaction")}>
                                <ListItemText primary="Transaction API" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton selected={pathname.includes("/reconciliation-api")} sx={{ py: 1.5 }} onClick={() => navigateDocumentation("reconciliation-api")}>
                            <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={"Reconciliation API"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton selected={pathname.includes("/refund-api")} sx={{ py: 1.5 }} onClick={() => navigateDocumentation("refund-api")}>
                            <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={"Refund API"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ParentMenuItem title="Payment Request">
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/send-payment")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("send-payment")}>
                                <ListItemText primary="Send Payment" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ParentMenuItem title="Recurring Billing">
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/recurring-billing")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("recurring-billing")}>
                                <ListItemText primary="Daily Fee" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/weekly-recurring-billing")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("weekly-recurring-billing")}>
                                <ListItemText primary="Weekly Fee" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/monthly-recurring-billing")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("monthly-recurring-billing")}>
                                <ListItemText primary="Monthly Fee" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/quarterly-recurring-billing")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("quarterly-recurring-billing")}>
                                <ListItemText primary="Quarterly Fee" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/semi-annually-recurring-billing")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("semi-annually-recurring-billing")}>
                                <ListItemText primary="Semi-Anually Fee" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/annually-recurring-billing")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("annually-recurring-billing")}>
                                <ListItemText primary="Anually Fee" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/terms-recurring-billing")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("terms-recurring-billing")}>
                                <ListItemText primary="Terms" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    {/* ================================================== */}
                    <ParentMenuItem title="Wallet (new)">
                        <List component="div" disablePadding>
                            <ListItemButton selected={pathname.includes("/wallet-airtel")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("wallet-airtel")}>
                                <ListItemText primary="Airtel" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/wallet-balance")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("wallet-balance")}>
                                <ListItemText primary="Balance Summary" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/wallet-checkout")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("wallet-checkout")}>
                                <ListItemText primary="3d Secure Checkout Page" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/wallet-mpesa")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("wallet-mpesa")}>
                                <ListItemText primary="Mpesa" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/wallet-refund")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("wallet-refund")}>
                                <ListItemText primary="Refund" />
                            </ListItemButton>
                            <ListItemButton selected={pathname.includes("/wallet-transaction")} sx={{ pl: 6, py: 0 }} onClick={() => navigateDocumentation("wallet-transaction")}>
                                <ListItemText primary="Transactions" />
                            </ListItemButton>
                        </List>
                    </ParentMenuItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton selected={pathname.includes("/woocommerce")} sx={{ py: 1.5 }} onClick={() => navigateDocumentation("woocommerce")}>
                            <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={"Woocommerce"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton selected={pathname.includes("/checkout")} sx={{ py: 1.5 }} onClick={() => navigateDocumentation("checkout")}>
                            <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={"Checkout Pages"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton selected={pathname.includes("/card-payment")} sx={{ py: 1.5 }} onClick={() => navigateDocumentation("card-payment")}>
                            <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={"Card Payment API"} />
                        </ListItemButton>
                    </ListItem>
                    {/*  */}
                    <ListItem disablePadding style={{ paddingLeft: 0 }}>
                        <ListItemButton selected={pathname.includes("/callback")} sx={{ py: 1.5 }} onClick={() => navigateDocumentation("callback")}>
                            <ListItemText primaryTypographyProps={{ pl: 3, fontWeight: '900' }} primary={"Callback"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Paper>
    );
}

export default DocumentationSideMenu;