import { Avatar, Box, Button, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationImage from '../../../containers/staticsite/dev-documentation-image';

const ApiKeyDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', paddingLeft: matches767 ? 0 : '5%', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                    <Box display={'flex'} sx={{ flexDirection: 'column', textAlign: 'center' }}>
                        <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>API Keys</Typography>
                    </Box>
                </Box>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>API Keys and Credentials</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            API keys and other credentials will be used for the merchant ato connect Pesaswap services like REST API, card payment checkout pages and woocommerce plugins from several payment processors.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to view API Keys and Credentials?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: Merchant can only view the credentials after required docs submission.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box>
                                    <DevDocumentationImage src="/images/api-documentation/api-key-ss-1.jpg" />
                                    <Typography variant='body1'>
                                        1. Go to Profile and Click "API Keys and Credentials"
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box>
                                    <DevDocumentationImage src="/images/api-documentation/api-key-ss-2.jpg" />
                                    <Typography variant='body1'>
                                        2. You can now see "API Keys and Credentials"
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>API Keys and Credentials</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to view API Keys and Credentials?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default ApiKeyDocumentation;
