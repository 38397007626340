import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import { useHistory } from 'react-router-dom';
import DevDocumentationTokenLink from '../../../containers/staticsite/dev-documentation-token-link';
import DevDocumentationDownloadLink from '../../../containers/staticsite/dev-documentation-download-link';
import mpesaV3DisbursementDownload from '../../../downloadables/disbursement/Mpesa v3 B2C.postman_collection.zip'

const DisbursementMpesaApiDocumentation = () => {
    const history = useHistory()
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Disbursement</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Mpesa Disbursement V3 API</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Another way to process b2c payment is via mpesa v3 disbursement API
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect into Pesaswap Mpesa Disbursement V3 API?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1' component={'span'}>
                            Note: You needed header token. Please refer to <DevDocumentationTokenLink />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Request
                                </Typography>
                                <Typography variant='body1' pt={1}>To submit a  disbursement you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap-csharp.azurewebsites.net/api/mpesa-disbursement/v3'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://api.pesaswap.com/api/mpesa-disbursement/v3'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationHeaders
                                    title="Headers"
                                    headers={['Field', 'Type', 'Value', 'Additional']}
                                    values={[
                                        ['Content-Type', 'string', 'application/json', 'Required'],
                                        ['Authorization', 'string', 'Value from Tokenization API.', 'Required']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' pb={2}>Sample HTML code</Typography>
                                <Typography variant='body1' component={'span'}>
                                    Download the <DevDocumentationDownloadLink label='Postman Collection for Mpesa V3 Disbursement API here.' document_title={'Mpesa V3 Disbursement API'} url={mpesaV3DisbursementDownload} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request'}
                                    content={{
                                        "MerchantIdentifier": "MerchantIdentifier",
                                        "TransactionExternalId": "TransactionExternalId",
                                        "Amount": "1",
                                        "Recipient": [
                                            {
                                                "Name": "Juan Delacruz",
                                                "Address": "Kenya",
                                                "EmailAddress": "Juan@ps.com",
                                                "PhoneNumber": "+254722101234",
                                                "IdType": "National ID",
                                                "IdNumber": "28475979",
                                                "FinancialInstitution": "Mpesa",
                                                "PrimaryAccountNumber": "254722101234",
                                                "Mccmnc": "63902",
                                                "Ccy": 404,
                                                "Country": "KE",
                                                "Purpose": "TEST"
                                            }
                                        ],
                                        "Remitter": [
                                            {
                                                "Name": "Juan Delacruz",
                                                "Email": "Juan@ps.com",
                                                "Address": "3068 Kessla Way",
                                                "PhoneNumber": "+254722101234",
                                                "IdType": "Social Security Number",
                                                "IdNumber": "657-18-9292",
                                                "IdIssuePlace": "Nairobi",
                                                "IdIssueDate": "1997/01/03",
                                                "IdExpireDate": "1997/01/03",
                                                "Nationality": "Kenyan",
                                                "DateOfBirth": "1997/01/03",
                                                "Country": "KE",
                                                "Ccy": 404,
                                                "FinancialInstitution": "Foo Credit Union",
                                                "SourceOfFunds": "Business",
                                                "PrincipalActivity": "Business",
                                                "state": "state",
                                                "City": "Nairobi",
                                                "PostalCode": "00100"
                                            }
                                        ]
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response'}
                                    content={{
                                        "Response": "Success. Request accepted for processing"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Error Response'}
                                    content={{
                                        "error": "Duplicate ExternalId."
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Mpesa Disbursement V3 API</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect into Pesaswap Mpesa Disbursement V3 API?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default DisbursementMpesaApiDocumentation;
