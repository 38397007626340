import { Box, Link } from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../styles/landing-page.scss';
import { useLocation } from 'react-router-dom';

const IndexPage = () => {
    const { hash } = useLocation()
    React.useEffect(() => {
        setTimeout(() => {
            document.querySelector('main').style.opacity = 1;
            document.querySelector('footer').style.opacity = 1;
            document.querySelector('main').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            document.querySelector('footer').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            var sectionElements = document.querySelectorAll("section");
            for (var index = 0; index < sectionElements.length; index++) {
                sectionElements[index].style.opacity = 1;
            }
            if (hash) {
                const testimonialSectionEl = document.querySelector(hash)
                testimonialSectionEl.scrollIntoView({ behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        }, 1000);
    }, []);

    return <div>
        <header className="af-class-section_header af-class-full_screen_-80vh">
            <div className="af-class-padding-global">
                <div className="af-class-container-xsmall">
                    <div className="af-class-padding-section-large">
                        <div className="af-class-text-align-center">
                            <div className="af-class-max-width-xlarge af-class-align-center">
                                <div className="af-class-margin-bottom af-class-margin-small">
                                    <h1 className="af-class-hero_title">Pesaswap makes digital payments <span className="af-class-hero_title_bold">easy</span>.</h1>
                                </div>
                                <div className="af-class-p-wrapper">
                                    <p className="af-class-text-size-medium">Enabling business of all sizes to accept and make payments. Pesaswap, your best option for handling digital payments.</p>
                                </div>
                                <div className="af-class-margin-top af-class-margin-medium">
                                    <div className="af-class-button-group af-class-is-center">
                                        <a href={`${window.location.origin}/v2/merchant-registration`} className="af-class-button w-button">Get Started</a>
                                        <a href="#payments" className="af-class-button af-class-is-secondary w-button">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="af-class-floating_icon_wrapper">
                <img src="/images/mastercard.svg" loading="lazy" data-w-id="c95503c6-971e-bd6e-57af-4be29f537c1a" alt="true" className="af-class-icon-1x1-large af-class-floating_1" />
                <img src="/images/MPESA.svg" loading="lazy" data-w-id="0860b202-fad9-7b7e-deab-d93617eb1d06" alt="true" className="af-class-icon-1x1-large af-class-floating_2" />
                <img src="/images/airtel.svg" loading="lazy" data-w-id="79480a2b-aca3-c5a9-dc0c-b26e36425dd8" alt="true" className="af-class-icon-1x1-large af-class-floating_3" />
                <img src="/images/VISA.svg" loading="lazy" data-w-id="014247f4-3972-c1d2-755e-f877e937324a" alt="true" className="af-class-icon-1x1-large af-class-floating_4" />
            </div> */}
        </header>
        <section className="af-class-section_cta">
            <div className="af-class-padding-global">
                <div className="af-class-container-xsmall">
                    <div className="af-class-padding-section-large">
                        <div className="af-class-text-align-center">
                            <div className="af-class-margin-bottom af-class-margin-small">
                                <h2 className="af-class-hero_title af-class-is-smaller">This is what Pesaswap can do for your business.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="payments" data-w-id="2574d8fb-155d-fd5d-b76a-6d007ccf5ded" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_layout">
            <div className="af-class-padding-global">
                <div className="af-class-container-large">
                    <div className="af-class-padding-section-large">
                        <div className="w-layout-grid af-class-layout_component">
                            <div className="af-class-layout_content">
                                <div className="af-class-margin-bottom af-class-margin-small">
                                    <h3 className="af-class-feature_ttile">Easily provide multiple payment methods to your customers.</h3>
                                </div>
                                <div className="af-class-margin-bottom af-class-margin-medium">
                                    <p className="af-class-text-size-medium">Multiple payment methods for your customers with seamless payment options for your business.</p>
                                </div>
                                <div className="af-class-layout_logo-row">
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Secure</div><img src="/images/convenient.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Fast</div><img src="/images/timely.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Cost Effective</div><img src="/images/cost-effective.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                </div>
                                <div className="af-class-margin-20-0">
                                    <div className="af-class-button-group">
                                        <Link component={RouterLink} to="/payments" className="af-class-button w-button">Manage your Payments</Link>
                                        {/* <a href="#" className="af-class-button w-button">Manaage your Payments</a> */}
                                        <div className="af-class-margin-left-wrapper">
                                            <a href="#reconcile" className="af-class-button af-class-is-link w-button">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="w-node-_2574d8fb-155d-fd5d-b76a-6d007ccf5dfe-b5133c56" className="af-class-layout_image-wrapper"><img src="/images/pesaswap-image-1.jpg" loading="lazy" sizes="(max-width: 991px) 90vw, 41vw" srcSet="/images/pesaswap-image-1.jpg 500w, /images/pesaswap-image-1.jpg 800w, /images/pesaswap-image-1.jpg 1080w, /images/pesaswap-image-1.jpg 1600w" alt="true" className="af-class-layout_image" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="reconcile" data-w-id="8704dadd-47bf-1fa5-27c2-e2af6f210607" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_layout">
            <div className="af-class-padding-global">
                <div className="af-class-container-large">
                    <div className="af-class-padding-section-large">
                        <div className="w-layout-grid af-class-layout_component">
                            <div id="w-node-_8704dadd-47bf-1fa5-27c2-e2af6f210627-b5133c56" className="af-class-layout_image-wrapper"><img src="/images/pesaswap-image-2.jpg" loading="lazy" sizes="(max-width: 991px) 90vw, 41vw" srcSet="/images/pesaswap-image-2.jpg 500w, /images/pesaswap-image-2.jpg 800w, /images/pesaswap-image-2.jpg 1080w, /images/pesaswap-image-2.jpg 1600w" alt="true" className="af-class-layout_image" /></div>
                            <div className="af-class-layout_content">
                                <div className="af-class-margin-bottom af-class-margin-small">
                                    <h3 className="af-class-feature_ttile">Reconcile payments from multiple sources.</h3>
                                </div>
                                <div className="af-class-margin-bottom af-class-margin-medium">
                                    <p className="af-class-text-size-medium">View all your payments from one dashboard eliminating the need to manage multiple records from different payment providers.</p>
                                </div>
                                <div className="af-class-layout_logo-row">
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Transparent</div><img src="/images/Group-1771.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Simple</div><img src="/images/Group-1770.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Reliable</div><img src="/images/Group-1769.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                </div>
                                <div className="af-class-margin-20-0">
                                    <div className="af-class-button-group">
                                        {/* <a href="#" className="af-class-button w-button">Reconcile your payments</a> */}
                                        <Link component={RouterLink} to="/reconciliation" className="af-class-button w-button">Reconcile your payments</Link>
                                        <div className="af-class-margin-left-wrapper">
                                            <a href="#data" className="af-class-button af-class-is-link w-button">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="data" data-w-id="111d79a5-0bef-91e8-7486-129cd1e626c8" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_layout">
            <div className="af-class-padding-global">
                <div className="af-class-container-large">
                    <div className="af-class-padding-section-large">
                        <div className="w-layout-grid af-class-layout_component">
                            <div className="af-class-layout_content">
                                <div className="af-class-margin-bottom af-class-margin-small">
                                    <h3 className="af-class-feature_ttile">Data &amp; Insights to scale your business.</h3>
                                </div>
                                <div className="af-class-margin-bottom af-class-margin-medium">
                                    <p className="af-class-text-size-medium">Make data driven decisions and use automation to increase your teams productivity.</p>
                                </div>
                                <div className="af-class-layout_logo-row">
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Robust</div><img src="/images/Robust.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Scalable</div><img src="/images/Scalable.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                    <div className="af-class-feature_icon_block">
                                        <div className="af-class-icon_feature_title">Future Fit</div><img src="/images/Future-Proof.svg" loading="lazy" alt="true" className="af-class-icon-1x1-medium-copy" />
                                    </div>
                                </div>
                                <div className="af-class-margin-20-0">
                                    <div className="af-class-button-group">
                                        {/* <a href="#" className="af-class-button w-button">Understand your payments</a> */}
                                        <Link component={RouterLink} to="/data-insights" className="af-class-button w-button">Insights from your payments data</Link>
                                        <div className="af-class-margin-left-wrapper">
                                            <a href="#testimonial" className="af-class-button af-class-is-link w-button">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="w-node-_111d79a5-0bef-91e8-7486-129cd1e626e8-b5133c56" className="af-class-layout_image-wrapper"><img src="/images/Group-1775.jpg" loading="lazy" sizes="(max-width: 991px) 90vw, 41vw" srcSet="/images/Group-1775.jpg 500w, /images/Group-1775.jpg 800w, /images/Group-1775.jpg 1080w, /images/Group-1775.jpg 1600w" alt="true" className="af-class-layout_image" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="testimonial" data-w-id="ef142ee4-76c2-b6f9-f94b-325ff288cb7b" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_testimonial">
            <div className="af-class-padding-global">
                <div className="af-class-container-large">
                    <div className="af-class-padding-section-large">
                        <div className="af-class-margin-bottom af-class-margin-xxlarge">
                            <div className="af-class-text-align-center">
                                <div className="af-class-max-width-large af-class-align-center">
                                    <div className="af-class-margin-bottom af-class-margin-small">
                                        <h2 className="af-class-feature_ttile">Why use Pesaswap?</h2>
                                    </div>
                                    <p className="af-class-text-size-medium">Hear from some of our customers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="af-class-testimonial18_component">
                            <div className="af-class-testimonial18_content">
                                <div className="af-class-testimonial18_rating-wrapper">
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                </div>
                                <div className="af-class-margin-bottom af-class-margin-small">
                                    <div className="af-class-text-size-medium">"Before we started using Pesaswap, our payment process was a mess. We had to deal with multiple payment gateways, high fees, and slow processing times. But since switching to Pesaswap, everything has been so much easier. We can now accept a wide range of payment methods, with low fees and fast processing. And the real-time reporting and analytics make reconciling payments a breeze. We're so glad we made the switch to Pesaswap!"</div>
                                </div>
                                <div className="af-class-testimonial18_client">
                                    <div className="af-class-testimonial18_client-info">
                                        <p className="af-class-text-weight-semibold">E-commerce shop owner in Kenya</p>
                                    </div>
                                </div>
                            </div>
                            <div className="af-class-testimonial18_content">
                                <div className="af-class-testimonial18_rating-wrapper">
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                </div>
                                <div className="af-class-margin-bottom af-class-margin-small">
                                    <div className="af-class-text-size-medium">"As a marketplace, we need to make sure that our sellers can easily and securely accept payments from buyers. Pesaswap has been a lifesaver for us. Their API is easy to integrate, and the customisable payment experience is perfect for our needs. Plus, the reconciliation and risk management features give us peace of mind. We're really happy with Pesaswap and would definitely recommend them to other marketplace businesses."</div>
                                </div>
                                <div className="af-class-testimonial18_client">
                                    <div className="af-class-testimonial18_client-info">
                                        <p className="af-class-text-weight-semibold">Senior Developer, Marketplace in Kenya</p>
                                    </div>
                                </div>
                            </div>
                            <div className="af-class-testimonial18_content">
                                <div className="af-class-testimonial18_rating-wrapper">
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                    <div className="af-class-testimonial18_rating-icon w-embed"><svg width="100%" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z" fill="currentColor" />
                                    </svg></div>
                                </div>
                                <div className="af-class-margin-bottom af-class-margin-small">
                                    <div className="af-class-text-size-medium">"As a domestic airline in Kenya, we need to be able to accept a wide range of payment methods, including mobile money. Pesaswap has been perfect for us. Their payment processing is fast, secure, and reliable, and the low fees make a big difference for our bottom line. Plus, the real-time reporting and analytics help us track our revenues and manage our finances. We're really happy with Pesaswap and would recommend them to any business looking to expand their payment options."</div>
                                </div>
                                <div className="af-class-testimonial18_client">
                                    <div className="af-class-testimonial18_client-info">
                                        <p className="af-class-text-weight-semibold">Product Manager, Domestic Airline in&nbsp;Kenya</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section data-w-id="ae67c618-b9b8-b229-9df2-822271018b37" style={{ opacity: 0, WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="af-class-section_cta">
            <div className="af-class-padding-global">
                <div className="af-class-container-large">
                    <div className="af-class-padding-section-large">
                        <div className="w-layout-grid af-class-cta51_component">
                            <div className="af-class-cta51_card">
                                <div className="af-class-text-align-center">
                                    <div className="af-class-max-width-large">
                                        <div className="af-class-margin-bottom af-class-margin-small">
                                            <h2 className="af-class-cta_title"><strong>Grow your business with seamless payment solutions.</strong></h2>
                                        </div>
                                        <p className="af-class-text-size-medium">Pesaswap makes it simple for businesses to take advantage of the growing digital economy. With support for multiple payment methods, low fees, and fast processing what are you waiting for?</p>
                                    </div>
                                </div>
                                <div className="af-class-margin-top af-class-margin-medium">
                                    <div className="af-class-button-group af-class-is-center">
                                        <a href={`${window.location.origin}/v2/merchant-registration`} className="af-class-button af-class-is-alternate w-button">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
};

export default IndexPage;