import { Link } from '@mui/material';
import React from 'react';
import '../../styles/contact.scss';
import { useHistory } from 'react-router-dom';

const DevDocumentationCheckoutTokenLink = () => {
    const history = useHistory()

    return (
        <Link
            underline='always'
            component={'button'}
            onClick={() => {
                history.push('/dev-documentation/checkout-tokenization')
            }}
            sx={{ color: '#02555b !important', fontWeight: '700 !important' }}
        >
            Checkout Tokenization API.
        </Link>
    )
}

export default DevDocumentationCheckoutTokenLink;