import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationContactUsLink from '../../../containers/staticsite/dev-documentation-contact-link';

const CardPaymentApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Regular Card Payment API</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>Regular Card</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Pesaswap offered a REST API for regular card payment(unenrolled 3d-secure card) with Fraud.Net banking API in the middle as a fraud manager.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect into Pesaswap Regular Card Payment API?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1'>
                            Note: You needed the endpoint(Live or Sandbox) API Key and Consumer Key.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Refund
                                </Typography>
                                <Typography variant='body1' pt={1}>To perform payment you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap.azurewebsites.net/api/regular/card-payment'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://www.pesaswap.com/api/regular/card-payment'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Body Request Parameters"
                                    headers={['Field', 'Description', 'Additional']}
                                    values={[
                                        ['consumer_key', <DevDocumentationContactUsLink statement='This serves as an api username. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['api_key', <DevDocumentationContactUsLink statement='This serves as an api user password. You can request this key from administrator or please contact us for more details.' />, 'Required'],
                                        ['currency', 'Money currency. IE. KES', 'Required'],
                                        ['amount', 'Amount to be paid.', 'Required'],
                                        ['expiry_date', 'Card expiration date. format(mmyyyy).', 'Required'],
                                        ['card_security_code', 'Cvv or cvc.', 'Required'],
                                        ['credit_card_number', 'credit or debit card number.', 'Required'],
                                        ['customer_external_id', 'Must be GUID/UUID. API user is required to integrate their customer to pesaswap platform. Customer API docs here.', 'Required'],
                                        ['transaction_external_id', 'A GUID / UUID format. This will be used for future queries like knowing what\'s the actual status of your payment transaction. We have three(3) transaction statuses. Pending, Completed and Failed.', 'Required'],
                                        ['environment', 'test or live.', 'Required']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request'}
                                    content={`
$url = BASE URL;
$curl_init();
curl_setopt($curl, CURLOPT_URL, $url); curl_setopt($curl, CURLOPT_HTTPHEADER, array('Content-Type:application/json'));

$curl_post_data = array(
'consumer_key' => ' ',
'api_key' => ' ',
'currency' => 'KES',
'amount' => ' ',
'expiry_date' => 'mmyyyy',
'card_security_code' => ' ',
'credit_card_number' => ' ',
'customer_external_id' => ' ',
'transaction_external_id' => ' ',
'environment' => ' ' );

$data_string = json_encode($curl_post_data);
curl_setopt($curl, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl, CURLOPT_POST, true);
curl_setopt($curl, CURLOPT_POSTFIELDS, $data_string);
$curl_response = curl_exec($curl);
$response = json_decode($curl_response,true);
                                    `}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response'}
                                    content={{ "success": "Payment Successful." }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Error Response'}
                                    content={{ "error": "Invalid Credentials. Please Check your consumer_key and api_key." }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingY={12} />
                    <Grid item xs={12}>
                        <DevDocumentationBody
                            title="Test Cards"
                            headers={['Card Number', 'Card Type', 'Expiry', 'CVV', 'Network']}
                            values={[
                                ['4000000000000002', 'Visa', '01/****', '111', 'Regular'],
                                ['4242424242424242', 'MasterCard', '01/****', '111', 'Regular'],
                                ['5200000000000007', 'MasterCard', '01/****', '111', '3d Secure'],
                                ['3520000000000922', 'JCB J/Secure', '01/****', '111', '3d Secure'],
                                ['340000000003961', 'American Express', '01/****', '111', '3d Secure'],
                                ['3005000000006246', 'Diners Club', '01/****', '111', '3d Secure'],
                                ['6011000000000004', 'Discover', '01/****', '111', '3d Secure'],
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} paddingY={12} />
                    <Grid item xs={12}>
                        <DevDocumentationBody
                            title="3d Secure Test OTP Creds"
                            headers={['Credentials']}
                            values={[
                                ['test123'],
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>Regular Card Payment API</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect into Pesaswap Regular Card Payment API?</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>Test Cards</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>3d Secure Test OTP Creds</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default CardPaymentApiDocumentation;
