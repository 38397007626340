import React from "react";
import IndexPage from '../static/pages';
import ContactUsStaticPage from "../static/pages/contact";
import DataInsightsStaticPage from "../static/pages/datainsights";
import DevDocumentationStaticPage from "../static/pages/devdocumentation";
import DevOverviewStaticPage from "../static/pages/devoverview";
import FaqsStaticPage from "../static/pages/faqs";
import PaymentsStaticPage from "../static/pages/payments";
import ReconciliationStaticPage from "../static/pages/reconciliation";
import PrivacyPolicyStaticPage from "../static/pages/privacy-policy";

const staticRoutes = [
    {
        path: '/',
        exact: true,
        component: IndexPage,
        isPrivate: false
    },
    {
        path: '/payments',
        exact: true,
        component: PaymentsStaticPage,
        isPrivate: false
    },
    {
        path: '/contact-us',
        exact: true,
        component: ContactUsStaticPage,
        isPrivate: false
    },
    {
        path: '/reconciliation',
        exact: true,
        component: ReconciliationStaticPage,
        isPrivate: false
    },
    {
        path: '/data-insights',
        exact: true,
        component: DataInsightsStaticPage,
        isPrivate: false
    },
    {
        path: '/faqs',
        exact: true,
        component: FaqsStaticPage,
        isPrivate: false
    },
    {
        path: '/dev-overview',
        exact: true,
        component: DevOverviewStaticPage,
        isPrivate: false
    },
    {
        path: '/dev-documentation',
        exact: true,
        component: DevDocumentationStaticPage,
        isPrivate: false
    },
    {
        path: '/privacy-policy',
        exact: true,
        component: PrivacyPolicyStaticPage,
        isPrivate: false
    }
];

export default staticRoutes;