import { Avatar, Box, Button, Chip, Collapse, Container, Divider, Drawer, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import '../../../styles/contact.scss';
import DevDocumentationTableOfContents from '../../../containers/staticsite/dev-documentation-toc';
import DevDocumentationHeaders from '../../../containers/staticsite/dev-documentation-headers';
import DevDocumentationBody from '../../../containers/staticsite/dev-documentation-body';
import DevDocumentationCode from '../../../containers/staticsite/dev-documentation-code';
import DevDocumentationResponse from '../../../containers/staticsite/dev-documentation-response';
import DevDocumentationTokenLink from '../../../containers/staticsite/dev-documentation-token-link';

const C2bApiDocumentation = () => {
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
    const matches767 = useMediaQuery(`(max-width:767px)`);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid container spacing={2} paddingX={"5%"} paddingBottom={'64px'}>
                    <Grid item xs={12}>
                        <Box display={'flex'} sx={{ width: '100%', paddingTop: '128px', paddingBottom: '64px', justifyContent: matches767 ? 'center' : 'flex-start', boxSizing: 'border-box' }}>
                            <Box display={'flex'} sx={{ flexDirection: 'column' }}>
                                <Typography sx={{ fontSize: '2.2rem', letterSpacing: '-0.5px', fontWeight: 700 }}>Collection</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>C2B API</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            The C2B API create a handshake particularly on Mpesa C2B using unique BillReferenceNo. Though the actual payment will be processed on mpesa portal using PS BillReferenceNo. It means BillReferenceNo is the key feature for both platforms.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={6} />
                    <Grid item xs={12}>
                        <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>How to connect into Pesaswap C2B API?</Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} alignItems={'center'} gap={3}>
                        <div style={{ borderRadius: '50rem', padding: '5px 30px', backgroundColor: "rgba(225, 237, 238, .3)", width: 'fit-content', color: '#02555b' }}>
                            Description
                        </div>
                        <Typography variant='body1' component={'span'}>
                            Note: You needed header token. Please refer to <DevDocumentationTokenLink />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Typography variant='h6' pt={2}>
                                    <Chip label="POST" color='success' sx={{ marginRight: 1 }} /> Submit Request
                                </Typography>
                                <Typography variant='body1' pt={1}>To submit collection you need to make a POST call to the following URL: </Typography>
                                -&nbsp; Sandbox: <DevDocumentationCode value={'https://devpesaswap-csharp.azurewebsites.net/api/mpesa-c2b-billrefno'} />
                                <br />
                                -&nbsp; Production: <DevDocumentationCode value={'https://api.pesaswap.com/api/mpesa-c2b-billrefno'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationHeaders
                                    title="Headers"
                                    headers={['Field', 'Type', 'Value', 'Additional']}
                                    values={[
                                        ['Content-Type', 'string', 'application/json', 'Required'],
                                        ['Authorization', 'string', 'Value from Tokenization API.', 'Required']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationBody
                                    title="Body Request Parameters"
                                    headers={['Field', 'Type', 'Value', 'Additional']}
                                    values={[
                                        ['PaybillDescription', 'string', '<your paybill description>', 'Required for Mpesa only'],
                                        ['Amount', 'number', '<desired amount>', 'Required'],
                                        ['CommandId', 'string', 'CustomerPayBillOnline', 'Required'],
                                        ['Msisdn', 'string', '<desired phone number>', 'Required'],
                                        ['ExternalId', 'string', '<your unique key identifier>', ''],
                                        ['BillRefNumber', 'string', '<desired bill ref number>', 'Required']
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Body Request'}
                                    content={{
                                        "PaybillDescription": "PaybillDescription",
                                        "Amount": 1,
                                        "CommandId": "CustomerPayBillOnline",
                                        "Msisdn": "254714451234",
                                        "ExternalId": "Your unique key identifier",
                                        "BillRefNumber": "BillRefNumber"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Success Response'}
                                    content={{
                                        "Response": "Success. Request accepted for processing"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} paddingY={1} />
                            <Grid item xs={12}>
                                <DevDocumentationResponse
                                    title={'Example Error Response'}
                                    content={{
                                        "error": "Duplicate ExternalId."
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
           <Grid item xs={4} display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}>
                <DevDocumentationTableOfContents>
                    <List dense sx={{ pl: '0!important' }} disablePadding>
                        <ListItemText sx={{ color: '#929292' }}>C2B API</ListItemText>
                        <ListItemText sx={{ color: '#929292' }}>How to connect into Pesaswap C2B API?</ListItemText>
                    </List>
                </DevDocumentationTableOfContents>
            </Grid>
        </Grid>
    );
}

export default C2bApiDocumentation;
