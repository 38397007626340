import { Box, Button, Container, Grid, TextField, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StaticSiteFooter from '../../containers/staticsite/footer';
import StaticSiteHeader from '../../containers/staticsite/header';
import '../../styles/static/contact-us.scss';
import axios from 'axios';

const ContactUsStaticPage = () => {
    const defaultFormData = {
        email_address: '', full_name: '', phone_number: '', message: ''
    }
    const [formData, setFormData] = useState(defaultFormData)

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('main').style.opacity = 1;
            document.querySelector('footer').style.opacity = 1;
            document.querySelector('main').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            document.querySelector('footer').style.transform = "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
            var sectionElements = document.querySelectorAll("section");
            for (var index = 0; index < sectionElements.length; index++) {
                sectionElements[index].style.opacity = 1;
            }
            window.scrollTo(0, 0);
        }, 1000);
    }, []);

    function validateEmail(emailField) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(emailField) == false) {
            return false;
        }
        return true;
    }

    const handleValidation = () => {
        const { email_address, full_name, phone_number, message } = formData
        const errorList = [];

        if (email_address.trim().length <= 0) { errorList.push('Enter an email address.'); }
        if (!validateEmail(email_address) && email_address.trim().length > 0) errorList.push('Enter a valid email address.');
        if (full_name.trim().length <= 0) errorList.push('Enter a full name.');
        if (phone_number.trim().length <= 0) errorList.push('Enter a phone number.');
        if (message.trim().length <= 0) errorList.push('Enter a message.');

        return errorList;
    };

    const handleSubmitEmail = () => {
        const { email_address, full_name, phone_number, message } = formData

        const validation = handleValidation()
        if (validation.length > 0) {
            window.alert(validation.join("\n"))
            return;
        }

        const protocol = window.location.protocol;
        const uri = window.location.host;
        const url = protocol + "//" + uri;
        const consumer_key = "null";
        const api_key = "null";

        var pesaswap_web_api = ['https://www.pesaswap.com', 'https://www.pesaswap.co.ke', 'https://pesaswap.com'].includes(window.location.origin) ? "https://pesaswap.com" : "https://devpesaswap.azurewebsites.net";
        var api_uri = true ? `${pesaswap_web_api}` : "http://localhost:8000";

        const urlPost = api_uri + '/api/pesaswap/email?consumer_key=' + consumer_key + '&api_key=' + api_key;

        const bodyData = JSON.stringify({
            email: email_address,
            name: full_name,
            phone: phone_number,
            message: message
        });

        axios.post(urlPost, bodyData, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            if (res?.data == "Email sent succesfully.") {
                window.alert('Sent succesfully.')
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setFormData(defaultFormData)
            document.getElementById('message-contact').innerText = ''
        })
    }

    return (
        <React.Fragment>
            <Box component="main" sx={{ pt: 3, pb: 2.25, width: '100%' }} >
                <Toolbar />
                <Container disableGutters sx={{ py: 1, height: '425px', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }} maxWidth={false}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', textAlign: 'center', gap: '26px', marginTop: '88px' }}>
                        <Box sx={{}}>
                            <Typography color="#02555b" sx={{ fontSize: '64px', fontWeight: 900, letterSpacing: '-1px', lineHeight: 1.3 }}>
                                Contact Us
                            </Typography>
                        </Box>
                        <Box sx={{}}>
                            <Typography color="#02555b" sx={{ fontSize: '25px', lineHeight: 1.3, fontWeight: 600, letterSpacing: '0px' }}>
                                We are happy to help! contact us for all your payment needs.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
                <Container disableGutters maxWidth={false} sx={{ display: 'flex', textAlign: 'center', alignItems: 'center', pt: 8 }}>
                    <Box display={'flex'} flexDirection={'column'} width="100%" pt={'41px'} sx={{ gap: '26px', paddingLeft: '125px', paddingRight: '39px' }} className="form-post-container">
                        {
                            ['Full Name', 'Email Address', 'Phone Number'].map((v, i) => {
                                let formName = v.toLowerCase()
                                formName = formName.replace(' ', '_')
                                return <Box key={i} sx={{ display: 'flex', alignItems: 'center', gap: '31px' }} className="form-label-field">
                                    <Box sx={{ width: '200px', textAlign: 'left' }} className="form-label">
                                        <Typography color="#737373" sx={{ fontSize: '20px', fontWeight: 500 }}>{v}</Typography>
                                    </Box>
                                    <Box sx={{ flex: 1, maxWidth: '955px' }} className="form-field">
                                        <TextField id="outlined-basic" variant="outlined" size="large" fullWidth margin="none"
                                            onChange={(e) => {
                                                setFormData({ ...formData, [formName]: e.target.value })
                                            }}
                                            value={formData?.[formName]}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": {
                                                        borderColor: "#f4f4f4"
                                                    },
                                                    "&:hover > fieldset": {
                                                        borderColor: "#f4f4f4"
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        borderColor: "#A1A1A1"
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                sx: {
                                                    height: '82px',
                                                    paddingLeft: '23px',
                                                    fontSize: '20px',
                                                    fontWeight: 500,
                                                    borderRadius: '18px',
                                                    color: '#A1A1A1',
                                                    backgroundColor: '#f4f4f4',
                                                }
                                            }} />
                                    </Box>
                                </Box>
                            })
                        }
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingRight: '39px', gap: '31px' }} className="form-label-field">
                            <Box sx={{ width: '200px', textAlign: 'left' }} className="form-label">
                                <Typography color="#737373" sx={{ fontSize: '20px', fontWeight: 500, paddingTop: '25px' }}>Message</Typography>
                            </Box>
                            <Box sx={{ flex: 1, maxWidth: '955px' }} className="form-field">
                                <Box component={"div"} contentEditable id="message-contact" placeholder='Type here...'
                                    onInput={(e) => {
                                        setFormData({ ...formData, 'message': e.currentTarget.textContent })
                                    }}
                                    sx={{
                                        border: '1px solid #f4f4f4',
                                        borderRadius: '18px',
                                        backgroundColor: '#f4f4f4',
                                        textAlign: 'left',
                                        padding: '26px 35px',
                                        fontSize: '20px',
                                        fontWeight: 500,
                                        height: '247px',
                                        overflow: 'auto',
                                        color: '#A1A1A1',
                                    }}>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ flex: 1, width: '100%', marginTop: '53px' }}>
                                <div className="af-class-button-group af-class-is-center">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        handleSubmitEmail()
                                    }} className="af-class-button w-button">Send Message</a>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
};

export default ContactUsStaticPage;